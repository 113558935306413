import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18next.use(HttpApi).use(initReactI18next).init({
  fallbackLng: "en",
});

i18next.services.formatter?.add("lowercase", (value) => value?.toLowerCase?.());
i18next.services.formatter?.add("uppercase", (value) => value?.toUpperCase?.());

export default i18next;
