import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { capacityType } from "@/client/private";
import { createBookablesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm from "@/components/auto-form";
import type { BookableSchemaValues } from "@/components/bookables/bookable-schema";
import { createBookableSchema } from "@/components/bookables/bookable-schema";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function AddBookableForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const create = useMutation({
    ...createBookablesMutation(),
  });

  const formSchema = createBookableSchema(t);
  const [formValues, setFormValues] = useState<BookableSchemaValues | null>(
    null,
  );

  const handleSubmit = (values: BookableSchemaValues) => {
    create.mutate(
      {
        body: {
          ...values,
          capacityType: capacityType.UNIQUE,
          channelId: [],
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.create_success", { item: t("bookables.bookable") }),
          );
          navigate({ to: "/bookables" });
        },
        onError: () =>
          toast.error(
            t("common.create_error", { item: t("bookables.bookable") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      disabled={create.isPending || !formValues}
      onClick={() => formValues && handleSubmit(formValues)}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemWrapper title={t("bookables.add.title")} buttons={buttons}>
      <Card>
        <CardHeader>
          <CardTitle>{t("bookables.bookable")}</CardTitle>
          <CardDescription>{t("bookables.add.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <AutoForm
            formSchema={formSchema}
            onValidityChange={setFormValues}
            fieldConfig={{
              description: {
                fieldType: "textarea",
              },
              capacityType: {
                inputProps: {
                  translateLabel: (label: string) =>
                    t(`bookables.capacity_type.${label.toLowerCase()}`),
                },
              },
              durationType: {
                inputProps: {
                  translateLabel: (label: string) =>
                    t(`bookables.duration_type.${label.toLowerCase()}`),
                },
              },
            }}
          ></AutoForm>
        </CardContent>
      </Card>
    </EditItemWrapper>
  );
}
