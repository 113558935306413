import type { ToSubOptions } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";
import type React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";

export function EditItemWrapper({
  title,
  backTo = { to: "../" },
  children,
  buttons,
  sidePanel,
}: React.PropsWithChildren<{
  title: string;
  buttons?: React.ReactNode;
  backTo?: ToSubOptions;
  sidePanel?: React.ReactNode;
}>) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const footer = document.getElementById("footer");

  return (
    <div className="2xl:mx-auto 2xl:w-10/12 grid flex-1 auto-rows-max gap-4">
      <div className="flex items-center justify-between w-full overflow-hidden gap-4">
        <div className="flex items-baseline gap-4 min-w-0">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => navigate(backTo)}
            title={t("common.back")}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">{t("common.back")}</span>
          </Button>
          <h1
            className="flex-1 shrink-0 text-2xl font-semibold tracking-tight truncate"
            title={title}
          >
            {title}
          </h1>
        </div>
        {buttons && <div className="hidden md:flex gap-2">{buttons}</div>}
      </div>
      <div className={sidePanel ? "grid lg:grid-cols-3 gap-4" : ""}>
        <div
          className={`grid auto-rows-max items-start gap-4 lg:gap-8 ${sidePanel ? "lg:col-span-2" : ""}`}
        >
          {children}
        </div>
        <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
          {sidePanel}
        </div>
      </div>
      {buttons &&
        footer &&
        createPortal(
          <div className="flex justify-center gap-2 p-4 w-full bg-muted/40">
            {buttons}
          </div>,
          footer,
        )}
    </div>
  );
}
