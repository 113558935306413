import {
  addMinutes,
  addYears,
  endOfDay,
  format,
  startOfDay,
  subMinutes,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { RRule } from "rrule";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useDateFormat } from "@/hooks/use-date-format";

type PatternResultsProps = {
  className?: string;
  rule: string;
  startDate: Date;
  endDate?: Date;
};

export function PatternResults({
  className,
  rule,
  startDate,
  endDate,
}: PatternResultsProps) {
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();

  const now = startOfDay(new Date());
  const hasEndDate = endDate != null;
  const end = hasEndDate ? endDate : addYears(startDate, 5);
  const endWithoutTz = subMinutes(
    endOfDay(end),
    endOfDay(end).getTimezoneOffset(),
  );

  // Format the start date without time zone
  const dtStart = `DTSTART:${format(startDate, "yyyyMMdd'T'HHmmss'Z'")}`;

  const rrule = new RRule({
    ...RRule.fromString(`${dtStart}\n${rule}`).origOptions,
    // Update the optional end date to pretend there is no time zone
    until: endWithoutTz,
  });

  // rrule does not play well with time zones, we have to pretend all dates without time zone
  const ruleOccurrences = rrule
    .between(now, endWithoutTz, true)
    .map((date) => addMinutes(date, date.getTimezoneOffset()));

  const lastOccurrence = hasEndDate ? ruleOccurrences.at(-1) : undefined;

  return (
    <Alert className={className}>
      <AlertTitle>{t("recurrence.pattern_results.title")}</AlertTitle>
      <AlertDescription>
        {t("recurrence.pattern_results.description", {
          count: Math.min(ruleOccurrences.length, 3),
        })}
        {hasEndDate && " "}
        {hasEndDate && t("recurrence.pattern_results.end_date_included")}
        <ul className="list-disc m-3">
          {ruleOccurrences.slice(0, 3).map((date) => (
            <li key={date.toISOString()}>{formatDate(date, "PPPPp")}</li>
          ))}
          {ruleOccurrences.length > 3 && lastOccurrence && (
            <>
              <li>...</li>
              <li>{formatDate(lastOccurrence, "PPPPp")}</li>
            </>
          )}
        </ul>
      </AlertDescription>
    </Alert>
  );
}
