import { useMutation } from "@tanstack/react-query";
import { userLoginMutation } from "@/client/public/@tanstack/react-query.gen.ts";
import { useAuthStore } from "@/store/auth/use-auth-store";

export const useLogin = () => {
  const { update } = useAuthStore();
  return useMutation({
    ...userLoginMutation(),
    onSuccess: (data) => {
      update(data);
    },
  });
};
