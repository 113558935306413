import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  addTimeToTime,
  getTimeDifference,
  isValidTimeString,
} from "@/components/recurrence/functions/time";

dayjs.extend(duration);

export function getDurationFromStartAndEndTime(
  startTime: string,
  endTime: string,
): string {
  const fallback = "PT0H";

  if (
    !isValidTimeString(startTime) ||
    !isValidTimeString(endTime) ||
    startTime >= endTime
  ) {
    return fallback;
  }

  const timeDifference = getTimeDifference(startTime, endTime);
  return `PT${timeDifference.hours}H${timeDifference.minutes}M`;
}

export function getEndTimeFromStartAndDuration(
  startTime: string,
  isoDuration: string,
) {
  const fallback = "24:00";

  if (!isValidTimeString(startTime) || !isValidDurationString(isoDuration)) {
    return fallback;
  }

  const duration = dayjs.duration(isoDuration);
  return addTimeToTime(startTime, duration.hours(), duration.minutes());
}

function isValidDurationString(duration: string): boolean {
  return /^P(?!$)(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(?=\d)(\d+H)?(\d+M)?(\d+S)?)?$/.test(
    duration ?? "",
  );
}
