import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { logoutMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import { useAuthStore } from "@/store/auth/use-auth-store";

export const useLogout = () => {
  const navigate = useNavigate();
  const { clear } = useAuthStore();

  return useMutation({
    ...logoutMutation(),
    onSuccess: async () => {
      clear();
      await navigate({ to: "/" });
    },
  });
};
