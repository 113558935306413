import { addMinutes, startOfDay } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { BookableRow } from "@/components/planner/BookableRow";
import DateSelection from "@/components/planner/DateSelection";
import { HeaderRow } from "@/components/planner/HeaderRow";
import ReservationCell from "@/components/planner/ReservationCell";
import type { ReservationRequestResources } from "@/components/planner/ReservationRequest";
import { ReservationRequests } from "@/components/planner/ReservationRequests";
import ZoomLevelSelect from "@/components/planner/ZoomLevelSelect";
import type {
  PlannerBookable,
  PlannerReservationRequest,
  PlannerResource,
} from "@/components/planner/planner-mock-data";
import {
  createMockResources,
  findRequest,
  findResource,
  getFlatReservations,
  getPlannerMockData,
} from "@/components/planner/planner-mock-data";
import type { ZoomLevel } from "@/components/planner/zoom-levels";
import { getZoomLevel } from "@/components/planner/zoom-levels";
import { Button } from "@/components/ui/button";
import { useElementSize } from "@/hooks/use-element-size";

const today = startOfDay(new Date());

export default function GridView() {
  const [currentDate, setCurrentDate] = useState(today);
  const [collapsedBookables, setCollapsedBookables] = useState<string[]>([]);
  const [zoomLevel, setZoomLevel] = useState(getZoomLevel("0"));
  const [bookables, setBookables] = useState<PlannerBookable[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { elementSize: containerSize } = useElementSize(containerRef);
  const [requestResources, setRequestResources] = useState<
    Map<PlannerResource["id"], PlannerReservationRequest["id"][]>
  >(new Map());

  useEffect(() => {
    const bookables = getPlannerMockData(today);
    setBookables(bookables);
  }, []);

  const { timeSlots, overlapsTimeSlots } = createTimeSlots(
    currentDate,
    zoomLevel,
    containerSize,
  );
  const filteredReservations = getFlatReservations(bookables).filter(
    (reservation) =>
      !collapsedBookables.includes(reservation.bookableId) &&
      overlapsTimeSlots(reservation),
  );
  const tableRowMapping = createTableRowMapping(bookables, collapsedBookables);

  const toggleBookable = (bookableId: string) => {
    setCollapsedBookables((prevCollapsedBookables) =>
      prevCollapsedBookables.includes(bookableId)
        ? prevCollapsedBookables.filter((id) => id !== bookableId)
        : [...prevCollapsedBookables, bookableId],
    );
  };

  const handleZoomLevelSelect = (zoomLevel: ZoomLevel) => {
    setZoomLevel(zoomLevel);
    setCurrentDate((prevDate) => startOfDay(prevDate));
  };

  const handleReservationRequestResourceSelection = (
    selection: ReservationRequestResources,
  ) => {
    setRequestResources((prevRequestResources) => {
      const newRequestResources = new Map(prevRequestResources);
      newRequestResources.set(selection.requestId, selection.resourceIds);
      return newRequestResources;
    });
  };

  const filteredRequests = Array.from(requestResources.entries())
    .flatMap(([requestId, resourceIds]) => {
      const request = findRequest(requestId, bookables);
      const resources = resourceIds
        .map((id) => findResource(id, bookables))
        .filter((r): r is PlannerResource => r != null);

      if (request == null || resources.length === 0) {
        return [];
      }

      return resources.map((resource) => ({
        ...request,
        resourceId: resource.id,
      }));
    })
    .filter((request) => overlapsTimeSlots(request));

  const addMockResources = () => {
    setBookables([...createMockResources(currentDate, bookables)]);
  };

  return (
    <div className="grid grid-rows-1 xl:grid-cols-[1fr_auto] gap-2 px-4 max-h-full">
      <div ref={containerRef} className="flex flex-col h-full">
        <div className="mb-4 flex justify-between items-center flex-wrap gap-1">
          <DateSelection
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            slotSizeMinutes={zoomLevel.slotSizeMinutes}
          />
          <div className="flex gap-2 items-center">
            <Button variant="outline" onClick={addMockResources}>
              Add mock reservations
            </Button>
            <ZoomLevelSelect
              selectedId={zoomLevel.id}
              onSelect={handleZoomLevelSelect}
            />
          </div>
        </div>
        <div
          className="grid"
          style={{
            gridTemplateColumns: `repeat(${timeSlots.length + 1}, minmax(0, 1fr))`,
            gridTemplateRows: `repeat(1, minmax(0, max-content))`,
          }}
        >
          <HeaderRow timeSlots={timeSlots} zoomLevel={zoomLevel} />
        </div>
        <div className="overflow-auto grow">
          <div
            className="grid"
            style={{
              gridTemplateColumns: `repeat(${timeSlots.length + 1}, minmax(0, 1fr))`,
              gridTemplateRows: `repeat(${tableRowMapping.size}, minmax(0, max-content))`,
            }}
          >
            {bookables.map((bookable, index, array) => (
              <BookableRow
                key={bookable.id}
                timeSlots={timeSlots}
                bookable={bookable}
                tableRowMapping={tableRowMapping}
                isLastBookable={index === array.length - 1}
                isCollapsed={collapsedBookables.includes(bookable.id)}
                handleCollapse={toggleBookable}
              />
            ))}
            {filteredReservations.map((reservation) => (
              <ReservationCell
                key={reservation.id}
                reservation={reservation}
                timeSlots={timeSlots}
                slotSizeMinutes={zoomLevel.slotSizeMinutes}
                isRequest={false}
                gridRow={
                  tableRowMapping.get(reservation.resourceId) ??
                  tableRowMapping.size + 2
                }
              />
            ))}
            {filteredRequests.map((request) => {
              return (
                <ReservationCell
                  key={`${request.id}-${request.resourceId}`}
                  reservation={request}
                  timeSlots={timeSlots}
                  slotSizeMinutes={zoomLevel.slotSizeMinutes}
                  isRequest={true}
                  gridRow={
                    tableRowMapping.get(request.resourceId) ??
                    tableRowMapping.size + 2
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
      <ReservationRequests
        bookables={bookables}
        handleSelectionChanged={handleReservationRequestResourceSelection}
      />
    </div>
  );
}

/**
 * Determine the time slots (start dates) based on the current date, zoom level and container size.
 */
function createTimeSlots(
  currentDate: Date,
  zoomLevel: ZoomLevel,
  containerSize: { width: number; height: number },
) {
  const remToPixels = (px: number) => {
    const pixelsPerRem =
      parseFloat(getComputedStyle(document.documentElement).fontSize) || 16;

    return px * pixelsPerRem;
  };

  const slotCount = Math.floor(containerSize.width / remToPixels(10));

  const firstSlotStart = addMinutes(
    currentDate,
    zoomLevel.startSlot * zoomLevel.slotSizeMinutes,
  );
  const lastSlotEnd = addMinutes(
    firstSlotStart,
    zoomLevel.slotSizeMinutes * slotCount,
  );
  const timeSlots = Array.from({ length: slotCount }, (_, i) =>
    addMinutes(firstSlotStart, i * zoomLevel.slotSizeMinutes),
  );

  function overlapsTimeSlots(item: { startAt: Date; until: Date }) {
    return item.startAt < lastSlotEnd && item.until > firstSlotStart;
  }

  return { firstSlotStart, lastSlotEnd, timeSlots, overlapsTimeSlots };
}

/**
 * Create a mapping between bookable and resource ID's and the grid row number.
 */
function createTableRowMapping(
  bookables: PlannerBookable[],
  collapsedBookables: string[],
) {
  const tableRowMapping = new Map<string, number>();

  let rowNumber = 1; // Grid rows start at 1, first row is for time slots
  bookables.forEach((bookable) => {
    tableRowMapping.set(bookable.id, rowNumber);
    rowNumber++;

    if (collapsedBookables.includes(bookable.id)) {
      return;
    }

    bookable.resources.forEach((resource) => {
      tableRowMapping.set(resource.id, rowNumber);
      rowNumber++;
    });
  });

  return tableRowMapping;
}
