import type { Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@/components/ui/checkbox";

export const selectCellId = "select";

interface SelectBoxProps<T> {
  row: Row<T>;
}

export const DataTableSelectCell = <T,>({ row }: SelectBoxProps<T>) => {
  const { t } = useTranslation();
  return (
    <Checkbox
      checked={row.getIsSelected()}
      onClick={(e) => e.stopPropagation()} // Prevent row selection on checkbox click
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label={t("table.select_row")}
    />
  );
};
