import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { createResourcesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm from "@/components/auto-form";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import type { ResourceSchemaValues } from "@/components/resources/resource-schema";
import { createResourceSchema } from "@/components/resources/resource-schema";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function AddResourceForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useQueryClient();
  const create = useMutation({
    ...createResourcesMutation(),
  });
  const route = getRouteApi("/_auth/resources/add");
  const { bookableId } = route.useSearch();

  const formSchema = createResourceSchema(t);
  const [formValues, setFormValues] = useState<ResourceSchemaValues | null>(
    null,
  );

  const handleSubmit = (values: ResourceSchemaValues) => {
    create.mutate(
      {
        body: {
          ...values,
          bookableId,
          unavailabilityId: [],
        },
      },
      {
        onSuccess: async () => {
          toast.success(
            t("common.create_success", { item: t("resources.resource") }),
          );
          await client.invalidateQueries();
          navigate({ to: "/bookables/$id", params: { id: bookableId } });
        },
        onError: () =>
          toast.error(
            t("common.create_error", { item: t("resources.resource") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      type="submit"
      disabled={create.isPending || !formValues}
      onClick={() => formValues && handleSubmit(formValues)}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemWrapper
      title={t("resources.add.title")}
      backTo={{ to: "/bookables/$id", params: { id: bookableId } }}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("resources.resource")}</CardTitle>
          <CardDescription>{t("resources.add.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <AutoForm formSchema={formSchema} onValidityChange={setFormValues} />
        </CardContent>
      </Card>
    </EditItemWrapper>
  );
}
