import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { addMinutes, startOfDay, subMinutes } from "date-fns";
import type { Dispatch, SetStateAction } from "react";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";

type DateSelectionProps = {
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  slotSizeMinutes: number;
};

export default function DateSelection({
  currentDate,
  setCurrentDate,
  slotSizeMinutes,
}: DateSelectionProps) {
  const handlePreviousTimeSlot = () => {
    setCurrentDate((prevDate) => subMinutes(prevDate, slotSizeMinutes));
  };

  const handleNextTimeSlot = () => {
    setCurrentDate((prevDate) => addMinutes(prevDate, slotSizeMinutes));
  };

  const handleDateSelect = (date: Date | undefined) => {
    setCurrentDate(startOfDay(date ?? new Date()));
  };

  return (
    <section className="flex items-center space-x-2 grow">
      <Button variant="outline" size="icon" onClick={handlePreviousTimeSlot}>
        <ChevronLeftIcon className="h-4 w-4" />
      </Button>
      <DatePicker date={currentDate} setDate={handleDateSelect} />
      <Button variant="outline" size="icon" onClick={handleNextTimeSlot}>
        <ChevronRightIcon className="h-4 w-4" />
      </Button>
    </section>
  );
}
