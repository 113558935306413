import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import type { PlannerReservation } from "@/components/planner/planner-mock-data";

type ReservationDetailsProps = {
  reservation: PlannerReservation;
};

export function ReservationDetails({ reservation }: ReservationDetailsProps) {
  const { t } = useTranslation();

  const popoverContent = [
    [
      t("reservations.properties.start_at"),
      format(reservation.startAt, "dd MMM HH:mm"),
    ],
    [
      t("reservations.properties.until"),
      format(reservation.until, "dd MMM HH:mm"),
    ],
    [t("reservations.properties.capacity"), reservation.capacity],
    [t("reservations.properties.notes"), reservation.notes],
  ];

  return (
    <>
      <span className="font-semibold">{reservation.title}</span>
      <ul className="grid gap-1">
        {popoverContent
          .filter(([, value]) => value)
          .map(([label, value]) => (
            <li key={label} className="flex justify-between">
              <span className="text-muted-foreground">{label}</span>
              <span>{value}</span>
            </li>
          ))}
      </ul>
    </>
  );
}
