import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import type { TFunction } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { z } from "zod";
import { onboardingUserMutation } from "@/client/public/@tanstack/react-query.gen.ts";
import AutoForm, { AutoFormSubmit } from "@/components/auto-form";
import { Languages, toBackendLanguage } from "@/lib/language.ts";

function createFormSchema(t: TFunction) {
  return z.object({
    firstName: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.first_name"),
        }),
      })
      .describe(t("settings.profile.first_name"))
      .min(3, {
        message: t("settings.profile.first_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.profile.first_name_max_length", { max: 160 }),
      }),

    lastName: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.last_name"),
        }),
      })
      .describe(t("settings.profile.last_name"))
      .min(3, {
        message: t("settings.profile.last_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.profile.last_name_max_length", { max: 160 }),
      }),

    email: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.email"),
        }),
      })
      .describe(t("settings.profile.email"))
      .email(t("settings.profile.email_invalid")),

    password: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.password"),
        }),
      })
      .describe(t("settings.profile.password"))
      .min(8, {
        message: t("settings.profile.password_min_length", { min: 8 }),
      })
      .max(256, {
        message: t("settings.profile.password_max_length", { max: 256 }),
      }),

    language: z.nativeEnum(Languages).describe(t("settings.profile.language")),

    companyName: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.general.company_name"),
        }),
      })
      .describe(t("settings.general.company_name"))
      .min(3, {
        message: t("settings.general.company_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.general.company_name_max_length", { max: 160 }),
      }),

    acceptTerms: z
      .boolean()
      .describe(t("registration.accept_terms_and_conditions"))
      .refine((value) => value, {
        message: t("registration.must_accept_terms_and_conditions"),
        path: ["acceptTerms"],
      }),
  });
}

export const RegisterForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formSchema = createFormSchema(t);
  const redirectOnSuccess = async () => {
    await navigate({ to: "/login" });
  };

  const register = useMutation({
    ...onboardingUserMutation(),
    onSuccess: redirectOnSuccess,
  });

  const handleSubmit = (values: z.infer<typeof formSchema>) => {
    register.mutate({
      body: {
        companyName: values.companyName,
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        language: toBackendLanguage(values.language as keyof typeof Languages),
        lastName: values.lastName,
      },
    });
  };

  return (
    <AutoForm
      formSchema={formSchema}
      onSubmit={handleSubmit}
      fieldConfig={{
        password: {
          inputProps: {
            type: "password",
          },
        },
        acceptTerms: {
          inputProps: {
            required: true,
          },
          description: (
            <Trans
              i18nKey="registration.agree_to_terms_and_conditions"
              components={{
                termsLink: (
                  <Link
                    className="text-primary underline"
                    to={"/terms"}
                    target={"_blank"}
                  />
                ),
              }}
            />
          ),
        },
      }}
    >
      <AutoFormSubmit>{t("registration.register")}</AutoFormSubmit>
    </AutoForm>
  );
};
