import type { Frequency } from "@/components/recurrence/models/frequency.model";
import type { RecurringRule } from "@/components/recurrence/models/recurring-rule.model";
import type { WeekDay } from "@/components/recurrence/models/week-day.model";

function parseTimeComponent(component: string | undefined) {
  return component?.padStart(2, "0") ?? "00";
}

export function parseRecurringRule(rule: string): RecurringRule {
  const parts = rule.replace("RRULE:", "").split(";");
  const dict = parts.reduce((acc, part) => {
    const [key, value] = part.split("=");
    if (key != null && value != null) {
      acc.set(key, value);
    }
    return acc;
  }, new Map<string, string>());

  return {
    frequency: (dict.get("FREQ") ?? "DAILY") as Frequency,
    startTime: `${parseTimeComponent(dict.get("BYHOUR"))}:${parseTimeComponent(dict.get("BYMINUTE"))}`,
    interval: dict.get("INTERVAL") ?? "1",
    weekDays: (dict.get("BYDAY")?.split(",") ?? [
      "MO",
      "TU",
      "WE",
      "TH",
      "FR",
      "SA",
      "SU",
    ]) as WeekDay[],
  };
}
