import { Link, Outlet } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
      <div className="mx-auto grid w-full max-w-6xl gap-2">
        <h1 className="text-3xl font-semibold">{t("settings.title")}</h1>
      </div>
      <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
        <nav className="grid gap-4 text-sm text-muted-foreground">
          <Link
            to="/settings/general"
            className="font-semibold text-muted-foreground [&.active]:text-accent-foreground"
          >
            {t("settings.general.title")}
          </Link>

          <Link
            to="/settings/opening-hours"
            className="font-semibold text-muted-foreground [&.active]:text-accent-foreground"
          >
            {t("opening_hours.title")}
          </Link>

          <Link
            to="/settings/profile"
            className="font-semibold text-muted-foreground [&.active]:text-accent-foreground"
          >
            {t("settings.profile.title")}
          </Link>
        </nav>
        <Outlet />
      </div>
    </main>
  );
};
