import { useTranslation } from "react-i18next";

export default function TermsEn() {
  const { t } = useTranslation();

  return (
    <>
      <p className="py-2 text-gray-500 dark:text-gray-400">
        {t("terms.last_updated", { date: "February 29, 2024" })}
      </p>
      <p>
        These terms and conditions outline the rules and regulations for the use
        of Shadcn&apos;s Website, located at https://shadcn.com/.
      </p>
      <p>
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use Shadcn if you do not agree to take
        all of the terms and conditions stated on this page.
      </p>
      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: &quot;Client&quot;,
        &quot;You&quot; and &quot;Your&quot; refers to you, the person log on
        this website and compliant to the Company’s terms and conditions.
        &quot;The Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;,
        &quot;Our&quot; and &quot;Us&quot;, refers to our Company.
        &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;, refers to
        both the Client and ourselves. All terms refer to the offer, acceptance
        and consideration of payment necessary to undertake the process of our
        assistance to the Client in the most appropriate manner for the express
        purpose of meeting the Client’s needs in respect of provision of the
        Company’s stated services, in accordance with and subject to, prevailing
        law of Netherlands. Any use of the above terminology or other words in
        the singular, plural, capitalization and/or he/she or they, are taken as
        interchangeable and therefore as referring to same.
      </p>
      <h2>License</h2>
      <p>
        Unless otherwise stated, Shadcn and/or its licensors own the
        intellectual property rights for all material on Shadcn. All
        intellectual property rights are reserved. You may access this from
        Shadcn for your own personal use subjected to restrictions set in these
        terms and conditions.
      </p>
      <h2>You must not:</h2>
      <ul>
        <li>Republish material from Shadcn</li>
        <li>Sell, rent or sub-license material from Shadcn</li>
        <li>Reproduce, duplicate or copy material from Shadcn</li>
        <li>Redistribute content from Shadcn</li>
      </ul>
      <h2>Hyperlinking to our Content</h2>
      <p>
        The following organizations may link to our Website without prior
        written approval:
      </p>
      <ol>
        <li>Government agencies;</li>
        <li>Search engines;</li>
        <li>News organizations;</li>
        <li>
          Online directory distributors may link to our Website in the same
          manner as they hyperlink to the Websites of other listed businesses;
          and
        </li>
        <li>
          System wide Accredited Businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups
          which may not hyperlink to our Web site.
        </li>
      </ol>
      <h2>Reservation of Rights</h2>
      <p>
        We reserve the right to request that you remove all links or any
        particular link to our Website. You approve to immediately remove all
        links to our Website upon request. We also reserve the right to amen
        these terms and conditions and it’s linking policy at any time. By
        continuously linking to our Website, you agree to be bound to and follow
        these linking terms and conditions.
      </p>
      <h2>Removal of links from our website</h2>
      <p>
        If you find any link on our Website that is offensive for any reason,
        you are free to contact and inform us any moment. We will consider
        requests to remove links but we are not obligated to or so or to respond
        to you directly.
      </p>
      <h2>Content Liability</h2>
      <p>
        We shall not be hold responsible for any content that appears on your
        Website. You agree to protect and defend us against all claims that is
        rising on your Website. No link(s) should appear on any Website that may
        be interpreted as libelous, obscene or criminal, or which infringes,
        otherwise violates, or advocates the infringement or other violation of,
        any third party rights.
      </p>
      <h2>Disclaimer</h2>
      <p>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will:
      </p>
      <ol>
        <li>
          limit or exclude our or your liability for death or personal injury;
        </li>
        <li>
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </li>
        <li>
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </li>
        <li>
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </li>
      </ol>
      <p>
        The limitations and prohibitions of liability set in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer,
        including liabilities arising in contract, in tort and for breach of
        statutory duty.
      </p>
      <h2>Dispute Resolution</h2>
      <p>
        The formation, interpretation, and performance of this Agreement and any
        disputes arising out of it shall be governed by the substantive and
        procedural laws of Netherlands without regard to its rules on conflicts
        or choice of law and, to the extent applicable, the laws of Netherlands.
        The exclusive jurisdiction and venue for actions related to the subject
        matter hereof shall be the state and federal courts located in
        Netherlands, and you hereby submit to the personal jurisdiction of such
        courts. You hereby waive any right to a jury trial in any proceeding
        arising out of or related to this Agreement. The United Nations
        Convention on Contracts for the International Sale of Goods does not
        apply to this Agreement.
      </p>
    </>
  );
}
