import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type {
  PageReservationDTO,
  ReservationResponseDTO,
} from "@/client/private";
import { listReservations, listView } from "@/client/private";
import { listReservationsQueryKey } from "@/client/private/@tanstack/react-query.gen";
import { AddItemButton } from "@/components/common/AddItemButton";
import { ItemListHeader } from "@/components/common/ItemListHeader";
import { DataTableLayoutWrapper } from "@/components/data-table/data-table-layout.tsx";
import { getReservationTableColumns } from "@/components/reservations/list/reservation-table-columns";
import { getReservationTableFilters } from "@/components/reservations/list/reservation-table-filters";
import { useDataTableSettings } from "@/hooks/use-data-table-settings.ts";

export const ReservationTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    content,
    totalPages,
    pageIndex,
    setPageIndex,
    queryKey,
    listSettings,
    totalElements,
    isFetched,
    refetch,
  } = useDataTableSettings<PageReservationDTO>(
    listReservations,
    listReservationsQueryKey,
    listView.RESERVATION,
  );

  const filterFields = useMemo(() => getReservationTableFilters(t), [t]);
  const columns = useMemo(
    () => getReservationTableColumns(t, refetch),
    [t, refetch],
  );

  const handleRowClick = (row: Row<ReservationResponseDTO>) => {
    navigate({ to: `/reservations/${row.id}` });
  };

  return (
    <DataTableLayoutWrapper
      above={
        <ItemListHeader
          title={t("reservations.title")}
          description={t("reservations.description")}
          totalElements={totalElements}
        />
      }
      toolbar={
        <AddItemButton
          onClick={() => navigate({ to: "/reservations/add" })}
          item={t("reservations.reservation")}
        />
      }
      columns={columns}
      content={content}
      totalPages={totalPages}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      onRowClick={handleRowClick}
      filterFields={filterFields}
      queryKey={queryKey}
      listSettings={listSettings}
      isFetched={isFetched}
    />
  );
};
