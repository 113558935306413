import { createFileRoute, redirect } from "@tanstack/react-router";
import { Settings } from "@/pages/Settings.tsx";

export const Route = createFileRoute("/_auth/settings")({
  beforeLoad: (options) => {
    if (options.location.pathname.match(/\/settings(\/)?$/)) {
      throw redirect({ to: "/settings/general" });
    }
  },
  component: Settings,
  staticData: {
    breadcrumb: "settings.title",
  },
});
