import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { ReservationResponseDTO } from "@/client/private";
import { updateReservationsMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm from "@/components/auto-form";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import type { ReservationSchemaValues } from "@/components/reservations/reservation-schema";
import { createReservationSchema } from "@/components/reservations/reservation-schema";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditReservationFormProps {
  reservation: ReservationResponseDTO;
}

export function EditReservationForm({ reservation }: EditReservationFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateReservationsMutation() });

  const formSchema = createReservationSchema(t);
  const [formValues, setFormValues] = useState<ReservationSchemaValues | null>(
    null,
  );

  const handleSubmit = (values: ReservationSchemaValues) => {
    update.mutate(
      {
        path: { id: reservation.id },
        body: {
          startAt: values.window.startAt,
          until: values.window.until,
          client: {
            name: values.clientName,
            email: values.clientEmail ?? undefined,
            phoneNumber: values.clientPhoneNumber ?? undefined,
          },
          resourceIdCapacityMap: {},
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("reservations.reservation") }),
          );
          navigate({ to: "/reservations" });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("reservations.reservation") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      disabled={update.isPending || !formValues}
      onClick={() => formValues && handleSubmit(formValues)}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemWrapper
      title={`${t("reservations.reservation")} ${reservation.client?.name}`}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("reservations.edit.title")}</CardTitle>
          <CardDescription>
            {t("reservations.edit.description")}
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-5">
          <AutoForm
            formSchema={formSchema}
            values={{
              window: {
                startAt: reservation.startAt,
                until: reservation.until,
              },
              clientName: reservation.client?.name,
              clientEmail: reservation.client?.email,
              clientPhoneNumber: reservation.client?.phoneNumber,
            }}
            onValidityChange={setFormValues}
            fieldConfig={{
              window: {
                startAt: {
                  fieldType: "dateTime",
                },
                until: {
                  fieldType: "dateTime",
                },
              },
            }}
          />
        </CardContent>
      </Card>
    </EditItemWrapper>
  );
}
