import { useTranslation } from "react-i18next";
import type { WeekDay } from "@/components/recurrence/models/week-day.model";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

type WeekDaySelectProps = {
  value: string[];
  onChange: (value: string[]) => void;
};

export function WeekDaySelect({ value, onChange }: WeekDaySelectProps) {
  const { t } = useTranslation();

  const weekDays: { id: WeekDay; label: string }[] = [
    { id: "MO", label: t("date_time.week_days.monday") },
    { id: "TU", label: t("date_time.week_days.tuesday") },
    { id: "WE", label: t("date_time.week_days.wednesday") },
    { id: "TH", label: t("date_time.week_days.thursday") },
    { id: "FR", label: t("date_time.week_days.friday") },
    { id: "SA", label: t("date_time.week_days.saturday") },
    { id: "SU", label: t("date_time.week_days.sunday") },
  ];

  return (
    <ToggleGroup
      type="multiple"
      className="flex-wrap justify-start"
      value={value}
      onValueChange={onChange}
    >
      {weekDays.map((weekDay) => (
        <ToggleGroupItem
          key={weekDay.id}
          value={weekDay.id}
          aria-label={weekDay.label}
        >
          {weekDay.label}
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
}
