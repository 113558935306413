import { useTranslation } from "react-i18next";
import { operation } from "@/client/private";
import { FormItem } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type OperationSelectProps = {
  value: operation;
  onChange: (value: operation) => void;
};

export function OperationSelect({ value, onChange }: OperationSelectProps) {
  const { t } = useTranslation();

  return (
    <FormItem>
      <Label>{t("opening_hours.properties.operation")}</Label>
      <Select onValueChange={onChange} value={value} required>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={operation.OPEN}>
            {t("opening_hours.operation.open")}
          </SelectItem>
          <SelectItem value={operation.CLOSED}>
            {t("opening_hours.operation.closed")}
          </SelectItem>
        </SelectContent>
      </Select>
    </FormItem>
  );
}
