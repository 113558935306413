import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { z } from "zod";
import { updateProfileMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm, { AutoFormSubmit } from "@/components/auto-form";
import { SkeletonCard } from "@/components/ui/SkeletonCard.tsx";
import { useProfile } from "@/hooks/use-profile.ts";
import { Languages, toBackendLanguage, toUiLanguage } from "@/lib/language.ts";

function createFormSchema(t: TFunction) {
  return z.object({
    firstName: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.first_name"),
        }),
      })
      .describe(t("settings.profile.first_name"))
      .min(3, {
        message: t("settings.profile.first_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.profile.first_name_max_length", { max: 160 }),
      }),
    lastName: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.last_name"),
        }),
      })
      .describe(t("settings.profile.last_name"))
      .min(3, {
        message: t("settings.profile.last_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.profile.last_name_max_length", { max: 160 }),
      }),
    language: z.nativeEnum(Languages).describe(t("settings.profile.language")),
  });
}

export const ProfileSettingsForm = () => {
  const client = useQueryClient();
  const { data, isLoading, queryKey: profileKey } = useProfile();
  const update = useMutation({ ...updateProfileMutation() });
  const { t, i18n } = useTranslation();
  const formSchema = createFormSchema(t);

  const handleSubmit = (values: z.infer<typeof formSchema>) => {
    const language = toBackendLanguage(values.language);
    update.mutate(
      {
        body: {
          firstName: values.firstName,
          lastName: values.lastName,
          language,
        },
      },
      {
        onSuccess: () => {
          i18n.changeLanguage(language.toLowerCase());
          toast.success(
            t("common.edit_success", { item: t("settings.profile.profile") }),
          );
          client.invalidateQueries({ queryKey: profileKey });
        },
        onError: () => {
          toast.error(
            t("common.edit_error", { item: t("settings.profile.profile") }),
          );
        },
      },
    );
  };

  if (isLoading) return <SkeletonCard />;

  return (
    <AutoForm
      formSchema={formSchema}
      values={{
        firstName: data?.firstName,
        lastName: data?.lastName,
        language: toUiLanguage(data?.language),
      }}
      onSubmit={handleSubmit}
    >
      <AutoFormSubmit disabled={update.isPending}>
        {t("common.save")}
      </AutoFormSubmit>
    </AutoForm>
  );
};
