import { Link } from "@tanstack/react-router";
import { User } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Avatar, AvatarFallback } from "@/components/ui/avatar.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { useLogout } from "@/hooks/use-logout.ts";
import { useProfile } from "@/hooks/use-profile.ts";
import { useAuthStore } from "@/store/auth/use-auth-store.ts";

export const ProfileMenu = () => {
  const { t } = useTranslation();
  const logout = useLogout();
  const email = useAuthStore((state) => state.identity?.email);
  const refreshToken = useAuthStore((state) => state.tokens?.refreshToken);
  const { data, isLoading } = useProfile();
  const firstLetter = `${data?.firstName[0]} ${data?.lastName[0]}`;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="overflow-hidden rounded-full"
        >
          <Avatar>
            <AvatarFallback>{isLoading ? "" : firstLetter}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem disabled>
          <User className="mr-2 h-4 w-4" />
          <span>{email}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <Link to="/settings">
          <DropdownMenuItem>{t("settings.title")}</DropdownMenuItem>
        </Link>
        <Link to="/support">
          <DropdownMenuItem>{t("support.title")}</DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            logout.mutate({ body: refreshToken! });
          }}
        >
          {t("authentication.logout")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
