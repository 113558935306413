import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import type { Filter, listView } from "@/client/private";
import {
  getListSettingOptions,
  getListSettingQueryKey,
} from "@/client/private/@tanstack/react-query.gen.ts";
import type { Content, QueryFn, QueryKeyFn } from "@/types/query";

export const useDataTableSettings = <T extends Content<T>>(
  queryFn: QueryFn<T>,
  queryKeyFn: QueryKeyFn,
  listSettingView: listView,
  additionalFilters: Filter[] = [],
) => {
  const defaultPageIndex = 0;
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);
  const { data: listSettings, isFetched } = useQuery({
    ...getListSettingOptions({ path: { listView: listSettingView } }),
  });

  const filters = [...(listSettings?.filters ?? []), ...additionalFilters];
  const listReq = {
    body: {
      filter: { filters },
      sort: {
        sortBy: listSettings?.sortBy,
        direction: listSettings?.direction,
      },
      pagination: {
        page: pageIndex,
        size: listSettings?.pageSize,
      },
    },
  };

  const queryKey = getListSettingQueryKey({
    path: {
      listView: listSettingView,
    },
  });

  const { data, refetch } = useQuery({
    enabled: isFetched && !!listSettings,
    queryKey: queryKeyFn(listReq),
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const { data } = await queryFn(listReq);
      return data;
    },
  });

  const content = data?.content || [];
  const totalPages = data?.totalPages || 0;
  const totalElements = data?.totalElements;

  return {
    pageIndex,
    setPageIndex,
    totalPages,
    content,
    queryKey,
    listSettings,
    totalElements,
    isFetched,
    refetch,
  };
};
