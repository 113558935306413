import type { TFunction } from "i18next";
import { z } from "zod";

export function createChannelSchema(t: TFunction) {
  return z.object({
    url: z
      .string({
        required_error: t("common.field_required", {
          field: t("channels.properties.url"),
        }),
      })
      .url({ message: t("channels.add.url_invalid") })
      .describe(t("channels.properties.url")),
    active: z.boolean().optional().describe(t("channels.properties.active")),
    bookingRange: z
      .string({
        required_error: t("common.field_required", {
          field: t("channels.properties.booking_range"),
        }),
      })
      .duration({ message: t("channels.add.booking_range_invalid") })
      .default("PT0H")
      .describe(t("channels.properties.booking_range")),
    prepareTime: z
      .string({
        required_error: t("common.field_required", {
          field: t("channels.properties.prepare_time"),
        }),
      })
      .duration({ message: t("channels.add.prepare_time_invalid") })
      .default("PT0H")
      .describe(t("channels.properties.prepare_time")),
  });
}

export type ChannelSchemaValues = z.infer<
  ReturnType<typeof createChannelSchema>
>;
