import { addHours, addMinutes, startOfDay } from "date-fns";
import { TimeSelect } from "@/components/common/TimeSelect";
import { getTimeComponents } from "@/components/recurrence/functions/time";
import { DatePicker } from "@/components/ui/date-picker";
import { FormControl, FormItem, FormMessage } from "@/components/ui/form";
import { useDateFormat } from "@/hooks/use-date-format";
import AutoFormLabel from "../common/label";
import AutoFormTooltip from "../common/tooltip";
import type { AutoFormInputComponentProps } from "../types";

export default function AutoFormDateTime({
  label,
  isRequired,
  field,
  fieldConfigItem,
  fieldProps,
}: AutoFormInputComponentProps) {
  const { showLabel, ...fieldPropsWithoutShowLabel } = fieldProps;
  const { formatDate } = useDateFormat();

  const date = startOfDay(field.value);
  const time = formatDate(field.value, "HH:mm");

  const handleChange = (date: Date) => {
    field.onChange(date);
    field.onBlur(); // This is needed to trigger validation (since auto form uses onTouched mode)
  };

  const handleDateChange = (newDate: Date) => {
    const { hour, minute } = getTimeComponents(time);
    handleChange(addHours(addMinutes(newDate, minute), hour));
  };

  const handleTimeChange = (newTime: string) => {
    const { hour, minute } = getTimeComponents(newTime);
    handleChange(addHours(addMinutes(date, minute), hour));
  };

  return (
    <FormItem>
      {(showLabel ?? true) && (
        <AutoFormLabel label={label} isRequired={isRequired} />
      )}
      <FormControl>
        <div className="flex flex-wrap gap-2">
          <DatePicker
            date={field.value}
            setDate={handleDateChange}
            {...fieldPropsWithoutShowLabel}
          />
          <TimeSelect value={time} onChange={handleTimeChange} />
        </div>
      </FormControl>
      <AutoFormTooltip fieldConfigItem={fieldConfigItem} />
      <FormMessage />
    </FormItem>
  );
}
