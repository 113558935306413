import { Link } from "@tanstack/react-router";
import { Trans, useTranslation } from "react-i18next";
import LoginForm from "@/components/forms/auth/login-form.tsx";

export const Login = () => {
  const { t } = useTranslation();

  return (
    <div className="relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
        <div className="absolute inset-0 bg-zinc-900" />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-2 h-6 w-6"
          >
            <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
          </svg>
          CBRM
        </div>
      </div>
      <div className="p-4 lg:p-8 h-full flex items-center">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              {t("login.login_or_register")}
            </h1>
            <p className="text-sm text-muted-foreground">
              {t("login.enter_credentials")}
            </p>
          </div>
          <LoginForm />
          <p className="px-8 text-center text-sm text-muted-foreground">
            <Trans
              i18nKey="login.you_agree_to_terms_and_conditions"
              components={{
                termsLink: (
                  <Link
                    to="/terms"
                    target="_blank"
                    className="underline underline-offset-4 hover:text-primary"
                  />
                ),
                privacyLink: (
                  <Link
                    to="/privacy"
                    target="_blank"
                    className="underline underline-offset-4 hover:text-primary"
                  />
                ),
              }}
            ></Trans>
          </p>
        </div>
      </div>
    </div>
  );
};
