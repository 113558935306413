import type {
  ColumnFilter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import {
  direction,
  type Filter,
  type ListSettingDTO,
  operator,
} from "@/client/private";

export const toFiltersSetting = (
  columnState: Array<ColumnFilter>,
  searchableColumnIds: Array<string>,
): Array<Filter> =>
  columnState.map((f) => {
    const isSearch = searchableColumnIds.includes(f.id);
    return {
      key: f.id,
      value: f.value as never,
      operator: isSearch ? operator.LIKE : operator.IN,
    };
  });

export const toSortingState = (settings: ListSettingDTO): SortingState => [
  {
    id: settings.sortBy,
    desc: settings.direction === direction.DESC,
  },
];

export const toSortingSetting = (sortingState: SortingState) => ({
  sortBy: sortingState.map((col) => col.id).at(0) || "id",
  direction: sortingState.map((col) => col.desc).at(0)
    ? direction.DESC
    : direction.ASC,
});

export const toPaginationState = (
  settings: ListSettingDTO,
  index: number,
): PaginationState => {
  return {
    pageIndex: index,
    pageSize: settings.pageSize,
  };
};

export const toFiltersState = (settings: ListSettingDTO): Array<ColumnFilter> =>
  settings.filters.map((filter) => ({
    id: filter.key!,
    value: filter.value!.map((index) => index),
  }));
