import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CopyIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import {
  getChannelsQueryKey,
  refreshApiKeyMutation,
} from "@/client/private/@tanstack/react-query.gen.ts";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface ApiKeyFormProps {
  channelId: string;
  apiKey: string;
}

export function ApiKeyForm({ apiKey, channelId }: ApiKeyFormProps) {
  const { t } = useTranslation();
  const client = useQueryClient();
  const { mutate, isPending } = useMutation({ ...refreshApiKeyMutation() });

  const copyApiKey = () => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => toast.success(t("channels.edit.api_key.copy_success")))
      .catch(() => toast.error(t("channels.edit.api_key.copy_error")));
  };

  const generateNewKey = () => {
    mutate(
      { path: { id: channelId } },
      {
        onSuccess: async () => {
          await client.invalidateQueries({
            queryKey: getChannelsQueryKey({ path: { id: channelId } }),
          });
          toast.success(t("channels.edit.api_key.generate_success"));
        },
      },
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("channels.edit.api_key.title")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-6">
          <div className="grid gap-3">
            <Label htmlFor="api-key">
              {t("channels.edit.api_key.current_value")}
            </Label>
            <div className="flex gap-3">
              <Input
                id="api-key"
                type="text"
                readOnly
                className="text-ellipsis"
                value={apiKey}
                title={apiKey}
              />
              <Button
                variant="outline"
                size="sm"
                className="h-100"
                title={t("channels.edit.api_key.copy")}
                onClick={copyApiKey}
              >
                <CopyIcon size="16" />
              </Button>
            </div>
            <Button size="sm" onClick={generateNewKey} disabled={isPending}>
              {t("channels.edit.api_key.generate_new")}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
