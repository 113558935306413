import type { ColumnDef } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { ChannelResponseDTO } from "@/client/private";
import { ChannelTableActionCell } from "@/components/channels/list/ChannelTableActionCell";
import { DataTableBooleanCell } from "@/components/data-table/cells/data-table-boolean-cell.tsx";
import { DataTableDurationCell } from "@/components/data-table/cells/data-table-duration-cell.tsx";
import { DataTableTextCell } from "@/components/data-table/cells/data-table-text-cell.tsx";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header.tsx";

export function getChannelTableColumns(
  t: TFunction,
  onDataInvalidated: () => void,
): Array<ColumnDef<ChannelResponseDTO>> {
  return [
    {
      accessorKey: "url",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("channels.properties.url")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<ChannelResponseDTO> row={row} name="url" />
      ),
    },
    {
      accessorKey: "active",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("channels.properties.active")}
        />
      ),
      cell: ({ row }) => (
        <DataTableBooleanCell<ChannelResponseDTO> row={row} name="active" />
      ),
      filterFn: (row, id, value) =>
        Array.isArray(value) && value.includes(row.getValue(id)),
    },
    {
      accessorKey: "bookingRange",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("channels.properties.booking_range")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDurationCell<ChannelResponseDTO>
          row={row}
          name="bookingRange"
        />
      ),
    },
    {
      accessorKey: "prepareTime",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("channels.properties.prepare_time")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDurationCell<ChannelResponseDTO>
          row={row}
          name="prepareTime"
        />
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <ChannelTableActionCell
          row={row}
          onDataInvalidated={onDataInvalidated}
        />
      ),
    },
  ];
}
