import { useTranslation } from "react-i18next";

export const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-blue-300 font-bold w-screen h-screen flex flex-col justify-center items-center">
      <p className="text-white text-6xl">{t("home.title")}</p>
    </div>
  );
};
