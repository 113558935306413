import type { PlannerResource } from "@/components/planner/planner-mock-data";

type ResourceRowProps = {
  resource: PlannerResource;
  timeSlots: Date[];
  tableRowMapping: Map<string, number>;
  isLastResource: boolean;
};

export function ResourceRow({
  resource,
  timeSlots,
  tableRowMapping,
  isLastResource,
}: ResourceRowProps) {
  const leftCellBorder = isLastResource ? "rounded-bl-md border-b" : "";
  const rightCellBorder = isLastResource ? "rounded-br-md" : "";

  return (
    <>
      <div
        className={`bg-background border-l border-muted px-2 text-sm text-foreground flex items-center ${leftCellBorder}`}
        style={{
          gridColumn: "1 / span 1",
          gridRow: `${tableRowMapping.get(resource.id)} / span 1`,
        }}
      >
        {resource.name}
      </div>
      {timeSlots.map((timeSlot, timeSlotIndex) => (
        <div
          key={`${resource.id}-${timeSlot}`}
          className={`border h-16 ${timeSlotIndex === timeSlots.length - 1 ? rightCellBorder : ""}`}
          style={{
            gridColumn: `${timeSlotIndex + 2} / span 1`,
            gridRow: `${tableRowMapping.get(resource.id) ?? tableRowMapping.size + 2} / span 1`,
          }}
        ></div>
      ))}
    </>
  );
}
