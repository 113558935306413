import type { TFunction } from "i18next";
import type { ResourceResponseDTO } from "@/client/private";
import type { DataTableFilterField } from "@/types/table.ts";

export function getResourceTableFilters(
  t: TFunction,
): Array<DataTableFilterField<ResourceResponseDTO>> {
  return [
    {
      label: t("resources.properties.name"),
      value: "name",
      placeholder: t("resources.properties.name_placeholder"),
    },
  ];
}
