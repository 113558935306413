import type { ColumnDef } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { UnavailabilityResponseDTO } from "@/client/private";
import { DataTableDateCell } from "@/components/data-table/cells/data-table-date-cell";
import { DataTableRecurrenceCell } from "@/components/data-table/cells/data-table-recurrence-cell";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header.tsx";
import { UnavailabilityActionCell } from "@/components/unavailability/list/UnavailabilityActionCell";

export function getUnavailabilityTableColumns(
  t: TFunction,
  onDataInvalidated: () => void,
): Array<ColumnDef<UnavailabilityResponseDTO>> {
  return [
    {
      accessorKey: "startAt",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("unavailability.properties.start")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDateCell<UnavailabilityResponseDTO>
          row={row}
          name="startAt"
        />
      ),
    },
    {
      accessorKey: "recurringRule",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("unavailability.properties.rule")}
        />
      ),
      cell: ({ row }) => (
        <DataTableRecurrenceCell<UnavailabilityResponseDTO>
          row={row}
          ruleProperty="recurringRule"
          durationProperty="recurringDuration"
        />
      ),
    },
    {
      accessorKey: "until",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("unavailability.properties.until")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDateCell<UnavailabilityResponseDTO> row={row} name="until" />
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <UnavailabilityActionCell
          row={row}
          onDataInvalidated={onDataInvalidated}
        />
      ),
    },
  ];
}
