import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { ChannelResponseDTO } from "@/client/private";
import { updateChannelsMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm from "@/components/auto-form";
import type { ChannelSchemaValues } from "@/components/channels/channel-schema";
import { createChannelSchema } from "@/components/channels/channel-schema";
import { ApiKeyForm } from "@/components/channels/edit/ApiKeyForm";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditChannelFormProps {
  channel: ChannelResponseDTO;
}

export function EditChannelForm({ channel }: EditChannelFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateChannelsMutation() });

  const formSchema = createChannelSchema(t);
  const [formValues, setFormValues] = useState<ChannelSchemaValues | null>(
    null,
  );

  const handleSubmit = (values: ChannelSchemaValues) => {
    update.mutate(
      {
        path: { id: channel.id },
        body: {
          ...values,
          active: values.active === true,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("channels.channel") }),
          );
          navigate({ to: "/channels" });
        },
        onError: () =>
          toast.error(t("common.edit_error", { item: t("channels.channel") })),
      },
    );
  };

  const buttons = (
    <Button
      disabled={update.isPending || !formValues}
      onClick={() => formValues && handleSubmit(formValues)}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemWrapper
      title={`${t("channels.channel")} ${channel.url}`}
      sidePanel={<ApiKeyForm channelId={channel.id} apiKey={channel.apiKey} />}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("channels.edit.title")}</CardTitle>
          <CardDescription>{t("channels.edit.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <AutoForm
            formSchema={formSchema}
            values={channel}
            onValidityChange={setFormValues}
          />
        </CardContent>
      </Card>
    </EditItemWrapper>
  );
}
