import type { ColumnDef } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { BookableResponseDTO } from "@/client/private";
import { BookableTableActionCell } from "@/components/bookables/list/BookableTableActionCell";
import { DataTableDurationCell } from "@/components/data-table/cells/data-table-duration-cell";
import { DataTableTextCell } from "@/components/data-table/cells/data-table-text-cell.tsx";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header.tsx";

export function getBookableTableColumns(
  t: TFunction,
  onDataInvalidated: () => void,
): Array<ColumnDef<BookableResponseDTO>> {
  return [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("bookables.properties.name")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<BookableResponseDTO> row={row} name="name" />
      ),
    },
    {
      accessorKey: "description",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("bookables.properties.description")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<BookableResponseDTO> row={row} name="description" />
      ),
    },
    {
      accessorKey: "capacityType",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("bookables.properties.capacity_type")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<BookableResponseDTO> row={row} name="capacityType" />
      ),
      accessorFn: (row) =>
        t(`bookables.capacity_type.${row.capacityType.toLowerCase()}`),
      filterFn: (row, id, value) =>
        Array.isArray(value) && value.includes(row.getValue(id)),
    },
    {
      accessorKey: "duration",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("bookables.properties.duration")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDurationCell<BookableResponseDTO> row={row} name="duration" />
      ),
    },
    {
      accessorKey: "durationType",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("bookables.properties.duration_type")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<BookableResponseDTO> row={row} name="durationType" />
      ),
      accessorFn: (row) =>
        t(`bookables.duration_type.${row.durationType.toLowerCase()}`),
      filterFn: (row, id, value) =>
        Array.isArray(value) && value.includes(row.getValue(id)),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <BookableTableActionCell
          row={row}
          onDataInvalidated={onDataInvalidated}
        />
      ),
    },
  ];
}
