import type { PropsWithChildren } from "react";
import { SideMenu } from "@/components/layout/SideMenu.tsx";
import { TopBar } from "@/components/layout/TopBar.tsx";
import { TooltipProvider } from "@/components/ui/tooltip.tsx";

export const Dashboard = ({ children }: PropsWithChildren) => {
  return (
    <TooltipProvider>
      <div className="flex w-full flex-col bg-muted/40">
        <SideMenu />
        <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14 h-dvh">
          <TopBar />
          <main className="overflow-scroll">{children}</main>
          <footer
            id="footer"
            className="md:hidden sticky bottom-0 bg-background"
          />
        </div>
      </div>
    </TooltipProvider>
  );
};
