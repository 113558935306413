import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/table-core";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { OpeningHourResponseDTO } from "@/client/private";
import { deleteOpeningHoursMutation } from "@/client/private/@tanstack/react-query.gen";
import { DataTableActionCell } from "@/components/data-table/cells/data-table-action-cell";

type ActionCellProps = {
  row: Row<OpeningHourResponseDTO>;
  onDataInvalidated: () => void;
};

export const OpeningHourTableActionCell = ({
  row,
  onDataInvalidated,
}: ActionCellProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteOpeningHour = useMutation({ ...deleteOpeningHoursMutation() });

  const handleEdit = (row: Row<OpeningHourResponseDTO>) => {
    navigate({ to: `/settings/opening-hours/${row.original.id}` });
  };

  const handleDelete = (row: Row<OpeningHourResponseDTO>) => {
    deleteOpeningHour.mutateAsync(
      {
        path: { id: row.original.id },
      },
      {
        onSuccess: () => {
          onDataInvalidated();
          toast.success(
            t("common.delete_success", {
              item: t("opening_hours.opening_hour"),
            }),
          );
        },
        onError: () =>
          toast.error(
            t("common.delete_error", { item: t("opening_hours.opening_hour") }),
          ),
      },
    );
  };

  return DataTableActionCell<OpeningHourResponseDTO>({
    row,
    actionHandlers: { handleEdit, handleDelete },
  });
};
