import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import type { TFunction } from "i18next";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import * as z from "zod";
import type { LoginRequest } from "@/client/public";
import { Button } from "@/components/ui/button.tsx";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useLogin } from "@/hooks/use-login.ts";

function createFormSchema(t: TFunction) {
  return z.object({
    email: z.string().email({ message: t("login.invalid_email") }),
    password: z.string(),
  });
}

export default function LoginForm() {
  const navigate = useNavigate();
  const login = useLogin();
  const { t } = useTranslation();

  const formSchema = createFormSchema(t);
  type UserFormValue = z.infer<typeof formSchema>;

  const defaultValues = {
    email: "",
    password: "",
  };

  const form = useForm<UserFormValue>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onSubmit = async (form: UserFormValue): Promise<void> => {
    const loginRequest: LoginRequest = {
      email: form.email,
      password: form.password,
    };

    await login.mutateAsync(
      { body: loginRequest },
      {
        onError: (error) => {
          toast.error(error.response?.data.message || t("login.login_error"), {
            description:
              error.response?.status === 500
                ? t("login.try_again")
                : t("login.change_credentials"),
            position: "top-center",
            important: true,
            dismissible: true,
          });
        },
        onSuccess: () => {
          navigate({ to: "/planner" });
        },
      },
    );
  };

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-2 w-full"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder={t("login.email_placeholder")}
                    disabled={login.isPending}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder={t("login.password_placeholder")}
                    disabled={login.isPending}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            disabled={login.isPending}
            className="ml-auto w-full"
            type="submit"
            onClick={form.handleSubmit(onSubmit)}
          >
            {login.isPending && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            {t("login.login")}
          </Button>
        </form>
      </Form>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            {t("login.or_create_an_account")}
          </span>
        </div>
      </div>
      <Button
        className="w-full"
        variant="outline"
        type="button"
        onClick={() => navigate({ to: "/register" })}
      >
        <Icons.add className="mr-2 h-4 w-4" />
        {t("login.sign_up")}
      </Button>
    </>
  );
}
