import { useMatches } from "@tanstack/react-router";
import { Slash } from "lucide-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.tsx";

export function Breadcrumbs() {
  const { t } = useTranslation();
  const breadcrumbs = useMatches()
    .map((m) => ({
      path: m.pathname,
      breadcrumb: m.staticData.breadcrumb ?? "",
    }))
    .filter((m) => m.breadcrumb !== "");

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbPage>CBRM</BreadcrumbPage>
        </BreadcrumbItem>

        {breadcrumbs.map(({ path, breadcrumb }, index, { length }) => (
          <Fragment key={path}>
            <BreadcrumbSeparator>
              <Slash />
            </BreadcrumbSeparator>

            <BreadcrumbItem>
              {index === length - 1 ? (
                <BreadcrumbPage>{t(breadcrumb)}</BreadcrumbPage>
              ) : (
                <BreadcrumbLink href={path}>{t(breadcrumb)}</BreadcrumbLink>
              )}
            </BreadcrumbItem>
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
