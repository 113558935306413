import { frequencyHasWeekDays } from "@/components/recurrence/functions/frequency-has-weekdays";
import { getTimeComponents } from "@/components/recurrence/functions/time";
import type { RecurringRule } from "@/components/recurrence/models/recurring-rule.model";

export function createRecurringRule(formValue: RecurringRule) {
  const freq = `FREQ=${formValue.frequency}`;
  const { hour, minute } = getTimeComponents(formValue.startTime);
  const byHour = `BYHOUR=${hour}`;
  const byMinute = `BYMINUTE=${minute}`;
  const weekStart = `WKST=MO`;
  const byDay =
    frequencyHasWeekDays(formValue.frequency) && formValue.weekDays.length > 0
      ? `BYDAY=${formValue.weekDays.join(",")}`
      : "";
  const interval = `INTERVAL=${formValue.interval}`;

  const parts = [freq, byHour, byMinute, weekStart, byDay, interval].filter(
    (part) => part.length > 0,
  );

  return parts.join(";");
}
