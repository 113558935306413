import type { TFunction } from "i18next";
import { z } from "zod";
import { language } from "@/client/private";
import { Languages, toUiLanguage } from "@/lib/language";

export const minPasswordLength = 8;

export function createPasswordSchema(t: TFunction, passwordRequired: boolean) {
  return z
    .string({
      required_error: t("common.field_required", {
        field: t("employees.properties.password"),
      }),
    })
    .describe(t("employees.properties.password"))
    .refine(
      (password) => {
        const length = password?.length ?? 0;
        return (
          (passwordRequired && length >= minPasswordLength) ||
          (!passwordRequired && (length === 0 || length >= minPasswordLength))
        );
      },
      {
        message: t("employees.add.password_min_length", {
          min: minPasswordLength,
        }),
      },
    );
}

function createPasswordConfirmationSchema(t: TFunction) {
  return z
    .string({
      required_error: t("common.field_required", {
        field: t("employees.properties.password_confirmation"),
      }),
    })
    .describe(t("employees.properties.password_confirmation"));
}

function createEmployeeBaseSchema(t: TFunction, defaultLanguage: language) {
  return z.object({
    firstName: z
      .string({
        required_error: t("common.field_required", {
          field: t("employees.properties.first_name"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("employees.properties.first_name"),
        }),
      })
      .describe(t("employees.properties.first_name")),
    lastName: z
      .string({
        required_error: t("common.field_required", {
          field: t("employees.properties.last_name"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("employees.properties.last_name"),
        }),
      })
      .describe(t("employees.properties.last_name")),
    language: z
      .nativeEnum(Languages, {
        required_error: t("common.field_required", {
          field: t("employees.properties.language"),
        }),
      })
      .default(toUiLanguage(defaultLanguage))
      .describe(t("employees.properties.language")),
    email: z
      .string({
        required_error: t("common.field_required", {
          field: t("employees.properties.email"),
        }),
      })
      .email({ message: t("employees.add.email_invalid") })
      .describe(t("employees.properties.email")),
  });
}

function checkPasswordConfirmation({
  password,
  confirmation,
}: {
  password?: string | undefined;
  confirmation?: string | undefined;
}) {
  return (password ?? "") === (confirmation ?? "");
}

function createPasswordConfirmationError(t: TFunction) {
  return {
    message: t("employees.add.passwords_must_match"),
    path: ["confirmation"],
  };
}

export function createAddEmployeeSchema(
  t: TFunction,
  defaultLanguage: language = language.EN,
) {
  return createEmployeeBaseSchema(t, defaultLanguage).merge(
    z.object({
      passwords: z
        .object({
          password: createPasswordSchema(t, true),
          confirmation: createPasswordConfirmationSchema(t),
        })
        .refine(checkPasswordConfirmation, createPasswordConfirmationError(t)),
    }),
  );
}

export function createEditEmployeeSchema(
  t: TFunction,
  defaultLanguage: language = language.EN,
) {
  return createEmployeeBaseSchema(t, defaultLanguage).merge(
    z.object({
      passwords: z
        .object({
          password: createPasswordSchema(t, false).optional(),
          confirmation: createPasswordConfirmationSchema(t).optional(),
        })
        .refine(checkPasswordConfirmation, createPasswordConfirmationError(t)),
    }),
  );
}

export type AddEmployeeSchemaValues = z.infer<
  ReturnType<typeof createAddEmployeeSchema>
>;

export type EditEmployeeSchemaValues = z.infer<
  ReturnType<typeof createEditEmployeeSchema>
>;
