import type { UseQueryResult } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  type Row,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type {
  OpeningHourResponseDTO,
  PageOpeningHourDTO,
} from "@/client/private";
import { AddItemButton } from "@/components/common/AddItemButton";
import { DataTable } from "@/components/data-table/data-table";
import { DataTableSkeleton } from "@/components/data-table/data-table-skeleton";
import { getOpeningHourTableColumns } from "@/components/opening-hours/list/opening-hour-table-columns.tsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

type OpeningHourTableProps = UseQueryResult<PageOpeningHourDTO> & {
  onDataInvalidated: () => void;
};

export const OpeningHourTable = ({
  data,
  isLoading,
  refetch,
  onDataInvalidated,
}: OpeningHourTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Opening hours should be sorted by order because it determines the order in which they are applied
  const content = useMemo(
    () => (data?.content ?? []).sort((a, b) => b.order - a.order),
    [data],
  );

  const columns = useMemo(() => {
    return getOpeningHourTableColumns(t, () => {
      refetch();
      onDataInvalidated();
    });
  }, [t, refetch, onDataInvalidated]);

  const table = useReactTable({
    columns,
    data: content,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualSorting: true,
    enableSorting: false,
    enableHiding: false,
  });

  const handleRowClick = (row: Row<OpeningHourResponseDTO>) => {
    navigate({ to: `/settings/opening-hours/${row.id}` });
  };

  if (!content || isLoading) {
    return <DataTableSkeleton columnCount={3} shrinkZero />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("opening_hours.title")}</CardTitle>
        <CardDescription>{t("opening_hours.description")}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-5">
        <div className="w-full flex justify-end">
          <AddItemButton
            onClick={() => navigate({ to: "/settings/opening-hours/add" })}
            item={t("opening_hours.opening_hour")}
          />
        </div>
        <DataTable table={table} onRowClick={handleRowClick} />
      </CardContent>
    </Card>
  );
};
