import { useMutation, useQuery } from "@tanstack/react-query";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { z } from "zod";
import {
  getCompanyOptions,
  updateCompanyMutation,
} from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm, { AutoFormSubmit } from "@/components/auto-form";
import { SkeletonCard } from "@/components/ui/SkeletonCard.tsx";

function createFormSchema(t: TFunction) {
  return z.object({
    name: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.general.company_name"),
        }),
      })
      .describe(t("settings.general.company_name"))
      .min(3, {
        message: t("settings.general.company_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.general.company_name_max_length", { max: 160 }),
      }),
  });
}

export const CompanySettingsForm = () => {
  const { data, isLoading } = useQuery({ ...getCompanyOptions() });
  const update = useMutation({ ...updateCompanyMutation() });
  const { t } = useTranslation();
  const formSchema = createFormSchema(t);

  const handleSubmit = (values: z.infer<typeof formSchema>) => {
    update.mutate(
      {
        body: {
          name: values.name,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("settings.general.company") }),
          );
        },
        onError: () => {
          toast.error(
            t("common.edit_error", { item: t("settings.general.company") }),
          );
        },
      },
    );
  };

  if (isLoading) return <SkeletonCard />;

  return (
    <AutoForm
      formSchema={formSchema}
      values={{
        name: data?.name,
      }}
      onSubmit={handleSubmit}
    >
      <AutoFormSubmit disabled={update.isPending}>
        {t("common.save")}
      </AutoFormSubmit>
    </AutoForm>
  );
};
