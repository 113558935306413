import { Plus } from "lucide-react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";

type AddItemButtonProps = {
  item: string;
  onClick: () => void;
};

export const AddItemButton = forwardRef<HTMLButtonElement, AddItemButtonProps>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <Button
        ref={ref}
        aria-label={t("common.add_item_label", {
          item: props.item,
        })}
        variant="outline"
        className="h-8 px-2 lg:px-3"
        {...props}
      >
        <Plus className="mr-2 h-4 w-4" />
        {t("common.add_item", { item: props.item })}
      </Button>
    );
  },
);

AddItemButton.displayName = "AddItemButton";
