import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { Dashboard } from "@/components/layout/Dashboard.tsx";

export const Route = createFileRoute("/_auth")({
  beforeLoad: ({ context }) => {
    // If the user is logged out, redirect them to the login page
    if (!context.token) {
      throw redirect({
        to: "/login",
      });
    }
  },
  component: () => (
    <Dashboard>
      <Outlet />
    </Dashboard>
  ),
});
