import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { startOfDay } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { createUnavailabilityMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import { RecurrenceForm } from "@/components/recurrence/RecurrenceForm";
import { createRecurringRule } from "@/components/recurrence/functions/create-recurring-rule";
import { getDurationFromStartAndEndTime } from "@/components/recurrence/functions/duration";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Form, FormField } from "@/components/ui/form";
import type { UnavailabilityFormData } from "@/components/unavailability/models/unavailability-form-data.model";
import { ResourceSelection } from "@/components/unavailability/resource-selection/ResourceSelection";

export function AddUnavailabilityForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const create = useMutation({
    ...createUnavailabilityMutation(),
  });

  const form = useForm<UnavailabilityFormData>({
    values: {
      startDate: startOfDay(new Date()),
      endDate: undefined,
      startTime: "08:00",
      endTime: "18:00",
      frequency: "DAILY",
      interval: "1",
      weekDays: ["MO", "TU", "WE", "TH", "FR", "SA", "SU"],
      resourceIds: [],
    },
    mode: "onChange",
  });

  const handleSubmit = (values: UnavailabilityFormData) => {
    const rule = createRecurringRule(values);

    create.mutate(
      {
        body: {
          startAt: values.startDate,
          until: values.endDate,
          recurringRule: rule,
          recurringDuration: getDurationFromStartAndEndTime(
            values.startTime,
            values.endTime,
          ),
          resourceId: values.resourceIds,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.create_success", {
              item: t("unavailability.unavailability"),
            }),
          );
          navigate({ to: "/unavailability" });
        },
        onError: () =>
          toast.error(
            t("common.create_error", {
              item: t("unavailability.unavailability"),
            }),
          ),
      },
    );
  };

  const sidePanel = (
    <FormField
      control={form.control}
      name="resourceIds"
      render={({ field }) => (
        <ResourceSelection value={field.value} onChange={field.onChange} />
      )}
    ></FormField>
  );

  const buttons = (
    <Button
      type="submit"
      disabled={create.isPending || !form.formState.isValid}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <EditItemWrapper
          title={t("unavailability.add.title")}
          sidePanel={sidePanel}
          buttons={buttons}
        >
          <Card>
            <CardHeader>
              <CardTitle>{t("unavailability.unavailability")}</CardTitle>
              <CardDescription>
                {t("unavailability.add.description")}
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-5">
              <RecurrenceForm />
            </CardContent>
          </Card>
        </EditItemWrapper>
      </form>
    </Form>
  );
}
