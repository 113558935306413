import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import type {
  DataTableActionHandler,
  DataTableActionHandlers,
} from "@/components/data-table/cells/data-table-action-handlers";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export type DataTableActionCellProps<T> = {
  row: Row<T>;
  actionHandlers: DataTableActionHandlers<T>;
};

export const DataTableActionCell = <T,>({
  row,
  actionHandlers,
}: DataTableActionCellProps<T>) => {
  const { t } = useTranslation();
  const { handleEdit, handleDelete } = actionHandlers;

  const handleActionClick = (actionHandler: DataTableActionHandler<T>) => {
    return (event: { stopPropagation: () => void }) => {
      event.stopPropagation();
      actionHandler(row);
    };
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            aria-label={t("table.open_menu_label")}
            variant="ghost"
            className="flex size-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="size-4" aria-hidden="true" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-40">
          {handleEdit && (
            <DropdownMenuItem onClick={handleActionClick(handleEdit)}>
              {t("common.edit")}
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          {handleDelete && (
            <DropdownMenuItem onClick={handleActionClick(handleDelete)}>
              {t("common.delete")}
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
