import { createFileRoute } from "@tanstack/react-router";
import { Edit } from "@/pages/settings/opening-hours/Edit";

export const Route = createFileRoute(
  "/_auth/settings/opening-hours/$id",
)({
  component: Edit,
  staticData: {
    breadcrumb: "opening_hours.edit.title",
  },
});
