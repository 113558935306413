import { getTimeComponents } from "@/components/recurrence/functions/time";
import { FormControl } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type TimeSelectProps = {
  value: string | undefined;
  onChange: (value: string) => void;
  includeEndOfDay?: boolean;
};

export function TimeSelect({
  value,
  onChange,
  includeEndOfDay = false,
}: TimeSelectProps) {
  const components = getTimeComponents(value ?? "");
  const hour = components.hour.toString().padStart(2, "0");
  const minute = components.minute.toString().padStart(2, "0");

  const onHourChange = (value: string) => {
    if (value === "24") {
      onChange("24:00");
    } else {
      onChange(`${value}:${minute}`);
    }
  };

  const onMinuteChange = (value: string) => {
    onChange(`${hour}:${value}`);
  };

  const hours = Array.from({ length: includeEndOfDay ? 25 : 24 }, (_, i) => i);
  const minutes =
    hour === "24" ? ["00"] : Array.from({ length: 60 }, (_, i) => i);

  return (
    <div className="flex gap-2 items-baseline">
      <Select onValueChange={onHourChange} value={hour}>
        <FormControl>
          <SelectTrigger className="w-16">
            <SelectValue />
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {hours.map((hour) => {
            const value = hour.toString().padStart(2, "0");
            return (
              <SelectItem key={hour} value={value}>
                {value}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
      :
      <Select onValueChange={onMinuteChange} value={minute}>
        <FormControl>
          <SelectTrigger className="w-16">
            <SelectValue />
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {minutes.map((minute) => {
            const value = minute.toString().padStart(2, "0");
            return (
              <SelectItem key={minute} value={value}>
                {value}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    </div>
  );
}
