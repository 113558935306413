import type { TFunction } from "i18next";
import type { ChannelResponseDTO } from "@/client/private";
import type { DataTableFilterField } from "@/types/table.ts";

export function getChannelTableFilters(
  t: TFunction,
): Array<DataTableFilterField<ChannelResponseDTO>> {
  return [
    {
      label: t("channels.properties.url"),
      value: "url",
      placeholder: t("channels.properties.url_placeholder"),
    },
    {
      label: t("channels.properties.active"),
      value: "active",
      options: [
        { label: t("common.yes"), value: "true" },
        { label: t("common.no"), value: "false" },
      ],
    },
  ];
}
