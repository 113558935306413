import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { capacityType, type BookableDetailResponseDTO } from "@/client/private";
import { updateBookablesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm from "@/components/auto-form";
import type { BookableSchemaValues } from "@/components/bookables/bookable-schema";
import { createBookableSchema } from "@/components/bookables/bookable-schema";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import { ResourceTable } from "@/components/resources/list/ResourceTable";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditBookableFormProps {
  bookable: BookableDetailResponseDTO;
}

export function EditBookableForm({ bookable }: EditBookableFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateBookablesMutation() });

  const formSchema = createBookableSchema(t);
  const [formValues, setFormValues] = useState<BookableSchemaValues | null>(
    null,
  );

  const handleSubmit = (values: BookableSchemaValues) => {
    update.mutate(
      {
        path: { id: bookable.id },
        body: {
          ...values,
          capacityType: capacityType.UNIQUE,
          channelId: [],
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("bookables.bookable") }),
          );
          navigate({ to: "/bookables" });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("bookables.bookable") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      disabled={update.isPending || !formValues}
      onClick={() => formValues && handleSubmit(formValues)}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemWrapper
      title={`${t("bookables.bookable")} ${bookable.name}`}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("bookables.edit.title")}</CardTitle>
          <CardDescription>{t("bookables.edit.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <AutoForm
            formSchema={formSchema}
            values={bookable}
            onSubmit={handleSubmit}
            onValidityChange={setFormValues}
            fieldConfig={{
              description: {
                fieldType: "textarea",
              },
              capacityType: {
                inputProps: {
                  translateLabel: (label: string) =>
                    t(`bookables.capacity_type.${label.toLowerCase()}`),
                },
              },
              durationType: {
                inputProps: {
                  translateLabel: (label: string) =>
                    t(`bookables.duration_type.${label.toLowerCase()}`),
                },
              },
            }}
          />
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            {t("resources.title")} ({bookable.resource.length})
          </CardTitle>
          <CardDescription>{t("resources.description")}</CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <ResourceTable
            bookableId={bookable.id}
            resourceIds={bookable.resource.map((r) => r.id)}
          />
        </CardContent>
      </Card>
    </EditItemWrapper>
  );
}
