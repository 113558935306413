import type { AxiosError } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { client } from "@/client/private";
import { userRefresh, type RefreshResult } from "@/client/public";
import { useAuthStore } from "@/store/auth/use-auth-store";

export const setPrivateClient = () => {
  client.setConfig({
    responseType: "json", // needed for hey-client response transformers to work
    timeout: 300_000,
    baseURL: "https://management.api.cbrm.cloud/api",
  });

  client.instance.interceptors.request.use(
    (config) => {
      const idToken = getIdToken();

      if (idToken) {
        config.headers.Authorization = "Bearer " + idToken;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  const refreshToken = async (failedRequest: AxiosError) => {
    const refreshToken = getRefreshToken();
    const username = getUsername();

    if (!refreshToken || !username) {
      clear();
      return;
    }

    try {
      const { data } = await userRefresh({
        body: { refreshToken: refreshToken },
      });
      if (data?.idToken && failedRequest.response) {
        setIdToken(data);
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + data.idToken;
        return;
      }
    } catch {
      clear();
      return Promise.reject(failedRequest);
    }
  };

  createAuthRefreshInterceptor(client.instance, refreshToken);
};

const getIdToken = () => useAuthStore.getState().tokens?.idToken;

const clear = () => {
  useAuthStore.setState({ tokens: undefined, identity: undefined });
};

const setIdToken = (result: RefreshResult) => {
  const tokens = useAuthStore.getState().tokens;
  if (!tokens) return;

  useAuthStore.getState().update({
    ...tokens,
    ...result,
  });
};

const getRefreshToken = () => {
  return useAuthStore.getState().tokens?.refreshToken;
};

const getUsername = () => {
  return useAuthStore.getState().identity?.sub;
};
