import type { Row } from "@tanstack/react-table";
import { useDateFormat } from "@/hooks/use-date-format";

interface DataTableDateCell<T> {
  row: Row<T>;
  name: Extract<keyof T, string>;
}

export const DataTableDateCell = <T,>({ row, name }: DataTableDateCell<T>) => {
  const { formatDate } = useDateFormat();
  const date = row.getValue<Date>(name);
  return date ? <span>{formatDate(date, "PPP")}</span> : null;
};
