import type { Row } from "@tanstack/react-table";

interface DataTableTextCell<T> {
  row: Row<T>;
  name: Extract<keyof T, string>;
}

export const DataTableTextCell = <T,>({ row, name }: DataTableTextCell<T>) => {
  const text = row.getValue<boolean>(name);
  return <span>{text}</span>;
};
