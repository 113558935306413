import type { ColumnDef } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { ReservationResponseDTO } from "@/client/private";
import { DataTableDateTimeCell } from "@/components/data-table/cells/data-table-date-time-cell";
import { DataTableTextCell } from "@/components/data-table/cells/data-table-text-cell.tsx";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header.tsx";
import { ReservationTableActionCell } from "@/components/reservations/list/ReservationTableActionCell";

export function getReservationTableColumns(
  t: TFunction,
  onDataInvalidated: () => void,
): Array<ColumnDef<ReservationResponseDTO>> {
  return [
    {
      accessorKey: "startAt",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.start_at")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDateTimeCell<ReservationResponseDTO>
          row={row}
          name="startAt"
        />
      ),
    },
    {
      accessorKey: "until",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.until")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDateTimeCell<ReservationResponseDTO> row={row} name="until" />
      ),
    },
    {
      accessorKey: "client",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.client")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<ReservationResponseDTO> row={row} name="client" />
      ),
      accessorFn: ({ client }) => `${client?.name} (${client?.email})`,
    },
    {
      accessorKey: "reservationStatus",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.status")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<ReservationResponseDTO>
          row={row}
          name="reservationStatus"
        />
      ),
      accessorFn: (row) =>
        t(`reservations.status.${row.reservationStatus.toLowerCase()}`),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <ReservationTableActionCell
          row={row}
          onDataInvalidated={onDataInvalidated}
        />
      ),
    },
  ];
}
