import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider, type createRouter } from "@tanstack/react-router";
import { ThemeProvider } from "next-themes";
import { setPrivateClient } from "@/api/private/axios-instance.ts";
import { setPublicClient } from "@/api/public/axios-instance.ts";
import { Toaster } from "@/components/ui/sonner";
import { LanguageProvider } from "@/providers/LanguageProvider.tsx";
import { useAuthStore } from "@/store/auth/use-auth-store.ts";

// import {
// 	TanStackRouterDevelopmentTools
// } from "@/components/utils/development-tools/TanStackRouterDevelopmentTools.tsx";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import { TanStackRouterDevelopmentTools } from "@/components/utils/development-tools/TanStackRouterDevelopmentTools";

type AppProps = { router: ReturnType<typeof createRouter> };
const queryClient = new QueryClient();
setPublicClient();
setPrivateClient();

const App = ({ router }: AppProps) => {
  const token = useAuthStore((state) => state.tokens?.idToken);

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
          <RouterProvider router={router} context={{ token: token }} />
          {/*<TanStackRouterDevelopmentTools*/}
          {/*	router={router}*/}
          {/*	initialIsOpen={false}*/}
          {/*	position="bottom-right"*/}
          {/*/>*/}
          <Toaster />
        </ThemeProvider>
      </LanguageProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
