import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/table-core";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { BookableResponseDTO } from "@/client/private";
import { deleteBookablesMutation } from "@/client/private/@tanstack/react-query.gen";
import { DataTableActionCell } from "@/components/data-table/cells/data-table-action-cell";

type ActionCellProps = {
  row: Row<BookableResponseDTO>;
  onDataInvalidated: () => void;
};

export const BookableTableActionCell = ({
  row,
  onDataInvalidated,
}: ActionCellProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteBookable = useMutation({ ...deleteBookablesMutation() });

  const handleEdit = (row: Row<BookableResponseDTO>) => {
    navigate({ to: `/bookables/${row.original.id}` });
  };

  const handleDelete = (row: Row<BookableResponseDTO>) => {
    deleteBookable.mutate(
      {
        path: { id: row.original.id },
      },
      {
        onSuccess: () => {
          onDataInvalidated();
          toast.success(
            t("common.delete_success", { item: t("bookables.bookable") }),
          );
        },
        onError: () =>
          toast.error(
            t("common.delete_error", { item: t("bookables.bookable") }),
          ),
      },
    );
  };

  return DataTableActionCell<BookableResponseDTO>({
    row,
    actionHandlers: { handleEdit, handleDelete },
  });
};
