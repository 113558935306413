import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { ResourceDetailResponseDTO } from "@/client/private";
import { updateResourcesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm from "@/components/auto-form";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import type { ResourceSchemaValues } from "@/components/resources/resource-schema";
import { createResourceSchema } from "@/components/resources/resource-schema";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditResourceProps {
  resource: ResourceDetailResponseDTO;
}

export function EditResourceForm({ resource }: EditResourceProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateResourcesMutation() });

  const formSchema = createResourceSchema(t);
  const [formValues, setFormValues] = useState<ResourceSchemaValues | null>(
    null,
  );

  const handleSubmit = (values: ResourceSchemaValues) => {
    update.mutate(
      {
        path: { id: resource.id },
        body: {
          ...values,
          bookableId: resource.bookableId,
          unavailabilityId: [],
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("resources.resource") }),
          );
          navigate({
            to: "/bookables/$id",
            params: { id: resource.bookableId },
          });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("resources.resource") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      disabled={update.isPending || !formValues}
      onClick={() => formValues && handleSubmit(formValues)}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemWrapper
      title={`${t("resources.resource")} ${resource.name}`}
      backTo={{
        to: "/bookables/$id",
        params: { id: resource.bookableId },
      }}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("resources.edit.title")}</CardTitle>
          <CardDescription>{t("resources.edit.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <AutoForm
            formSchema={formSchema}
            values={resource}
            onValidityChange={setFormValues}
          />
        </CardContent>
      </Card>
    </EditItemWrapper>
  );
}
