import type { TFunction } from "i18next";
import { z } from "zod";
import { capacityType, durationType } from "@/client/private";

export function createBookableSchema(t: TFunction) {
  return z.object({
    name: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.name"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("bookables.properties.name"),
        }),
      })
      .describe(t("bookables.properties.name")),
    description: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.description"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("bookables.properties.description"),
        }),
      })
      .describe(t("bookables.properties.description")),
    capacityType: z
      .enum([capacityType.SHARED, capacityType.UNIQUE], {
        required_error: t("common.field_required", {
          field: t("bookables.properties.capacity_type"),
        }),
      })
      .default(capacityType.UNIQUE)
      .describe(t("bookables.properties.capacity_type")),
    duration: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.duration"),
        }),
      })
      .duration({ message: t("bookables.add.duration_invalid") })
      .describe(t("bookables.properties.duration")),
    durationType: z
      .enum([durationType.FIXED, durationType.UNTIL_OPERATION_CLOSED])
      .default(durationType.FIXED)
      .describe(t("bookables.properties.duration_type")),
  });
}

export type BookableSchemaValues = z.infer<
  ReturnType<typeof createBookableSchema>
>;
