import type { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { frequencyToTranslationKey } from "@/components/recurrence/models/frequency-to-translation-key.model";
import type { Frequency } from "@/components/recurrence/models/frequency.model";
import { FormControl } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type IntervalSelectProps = {
  frequency: Frequency;
  value: string;
  onChange: (value: string) => void;
};

export function IntervalSelect({
  frequency,
  value,
  onChange,
}: IntervalSelectProps) {
  const { t } = useTranslation();

  const intervalOptions = useMemo(
    () =>
      getIntervalOptions(
        t,
        frequencyToTranslationKey[frequency],
        frequencyToIntervalCount[frequency],
      ),
    [t, frequency],
  );

  return (
    <Select onValueChange={onChange} value={value}>
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {intervalOptions.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

const getIntervalOptions = (
  t: TFunction,
  translationKey: string,
  count: number,
) => {
  return Array.from({ length: count }, (_, i) => i + 1).map((i) => ({
    value: i.toString(),
    label: t(translationKey, { count: i }),
  }));
};

const frequencyToIntervalCount: Record<Frequency, number> = {
  DAILY: 31,
  WEEKLY: 52,
  MONTHLY: 24,
  YEARLY: 10,
};
