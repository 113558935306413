import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { BookableResponseDTO, PageBookableDTO } from "@/client/private";
import { listBookables, listView } from "@/client/private";
import { listBookablesQueryKey } from "@/client/private/@tanstack/react-query.gen";
import { getBookableTableColumns } from "@/components/bookables/list/bookable-table-columns";
import { getBookableTableFilters } from "@/components/bookables/list/bookable-table-filters";
import { AddItemButton } from "@/components/common/AddItemButton";
import { ItemListHeader } from "@/components/common/ItemListHeader";
import { DataTableLayoutWrapper } from "@/components/data-table/data-table-layout.tsx";
import { useDataTableSettings } from "@/hooks/use-data-table-settings.ts";

export const BookableTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    content,
    totalPages,
    pageIndex,
    setPageIndex,
    queryKey,
    listSettings,
    totalElements,
    isFetched,
    refetch,
  } = useDataTableSettings<PageBookableDTO>(
    listBookables,
    listBookablesQueryKey,
    listView.BOOKABLE,
  );

  const filterFields = useMemo(() => getBookableTableFilters(t), [t]);
  const columns = useMemo(
    () => getBookableTableColumns(t, refetch),
    [t, refetch],
  );

  const handleRowClick = (row: Row<BookableResponseDTO>) => {
    navigate({ to: `/bookables/${row.id}` });
  };

  return (
    <DataTableLayoutWrapper
      above={
        <ItemListHeader
          title={t("bookables.title")}
          description={t("bookables.description")}
          totalElements={totalElements}
        />
      }
      toolbar={
        <AddItemButton
          onClick={() => navigate({ to: "/bookables/add" })}
          item={t("bookables.bookable")}
        />
      }
      columns={columns}
      content={content}
      totalPages={totalPages}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      onRowClick={handleRowClick}
      filterFields={filterFields}
      queryKey={queryKey}
      listSettings={listSettings}
      isFetched={isFetched}
    />
  );
};
