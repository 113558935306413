import { useQuery } from "@tanstack/react-query";
import { getRouteApi } from "@tanstack/react-router";
import { getOpeningHoursOptions } from "@/client/private/@tanstack/react-query.gen";
import { FormSkeleton } from "@/components/forms/FormSkeleton.tsx";
import { EditOpeningHourForm } from "@/components/opening-hours/edit/EditOpeningHourForm";

export const Edit = () => {
  const route = getRouteApi("/_auth/settings/opening-hours/$id");
  const { id } = route.useParams();
  const { data, isLoading } = useQuery({
    ...getOpeningHoursOptions({ path: { id } }),
  });

  if (isLoading || !data) {
    return <FormSkeleton />;
  }

  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-4 lg:grid-cols-1 xl:grid-cols-1">
      <EditOpeningHourForm openingHour={data} />
    </main>
  );
};
