import type { TFunction } from "i18next";
import type { EmployeeResponseDTO } from "@/client/private";
import type { DataTableFilterField } from "@/types/table.ts";

export function getEmployeeTableFilters(
  t: TFunction,
): Array<DataTableFilterField<EmployeeResponseDTO>> {
  return [
    {
      label: t("employees.properties.first_name"),
      value: "firstName",
      placeholder: t("employees.properties.first_name_placeholder"),
    },
  ];
}
