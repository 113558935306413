export function getTimeComponents(time: string) {
  if (!isValidTimeString(time)) {
    return { hour: 0, minute: 0 };
  }

  const [hour = 0, minute = 0] = time.split(":").map((t) => parseInt(t, 10));
  return { hour, minute };
}

export function addTimeToTime(
  startTime: string,
  hours: number,
  minutes: number,
) {
  const { hour: startHour, minute: startMinute } = getTimeComponents(startTime);
  const totalMinutes = startHour * 60 + startMinute + hours * 60 + minutes;
  const newHour = Math.floor(totalMinutes / 60);
  const newMinute = totalMinutes % 60;
  return `${newHour.toString().padStart(2, "0")}:${newMinute.toString().padStart(2, "0")}`;
}

export function getTimeDifference(startTime: string, endTime: string) {
  const { hour: startHour, minute: startMinute } = getTimeComponents(startTime);
  const { hour: endHour, minute: endMinute } = getTimeComponents(endTime);

  const startMinutes = startHour * 60 + startMinute;
  const endMinutes = endHour * 60 + endMinute;
  const difference = endMinutes - startMinutes;

  return {
    hours: difference >= 60 ? Math.floor(difference / 60) : 0,
    minutes: difference % 60,
  };
}

export function isValidTimeString(time: string): boolean {
  return /^[0-9]{1,2}:[0-9]{1,2}$/.test(time ?? "");
}
