import { createFileRoute } from "@tanstack/react-router";
import { Add } from "@/pages/resources/Add";

export const Route = createFileRoute("/_auth/resources/add")({
  component: Add,
  validateSearch: (search) => {
    return {
      bookableId: String(search["bookableId"]),
    };
  },
  staticData: {
    breadcrumb: "resources.add.title",
  },
});
