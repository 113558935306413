import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Info } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { EmployeeResponseDTO } from "@/client/private";
import { updateEmployeeMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm from "@/components/auto-form";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import type { EditEmployeeSchemaValues } from "@/components/employees/employee-schema";
import {
  createEditEmployeeSchema,
  minPasswordLength,
} from "@/components/employees/employee-schema";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { toBackendLanguage, toUiLanguage } from "@/lib/language";

interface EditEmployeeFormProps {
  employee: EmployeeResponseDTO;
}

export function EditEmployeeForm({ employee }: EditEmployeeFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateEmployeeMutation() });

  const formSchema = createEditEmployeeSchema(t);
  const [formValues, setFormValues] = useState<EditEmployeeSchemaValues | null>(
    null,
  );

  formSchema.shape.firstName.optional();

  const handleSubmit = (values: EditEmployeeSchemaValues) => {
    update.mutate(
      {
        path: { id: employee.id },
        body: {
          ...values,
          password: values.passwords.password ?? "", // TODO: remove this when backend allows leaving password empty
          language: toBackendLanguage(values.language),
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("employees.employee") }),
          );
          navigate({ to: "/employees" });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("employees.employee") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      disabled={update.isPending || !formValues}
      onClick={() => formValues && handleSubmit(formValues)}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemWrapper
      title={`${t("employees.employee")} ${employee.firstName} ${employee.lastName}`}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("employees.edit.title")}</CardTitle>
          <CardDescription>{t("employees.edit.description")}</CardDescription>
        </CardHeader>
        <CardContent className="space-y-5">
          <AutoForm
            formSchema={formSchema}
            values={{
              ...employee,
              language: toUiLanguage(employee.language),
            }}
            onValidityChange={setFormValues}
            fieldConfig={{
              passwords: {
                password: {
                  inputProps: { type: "password" },
                },
                confirmation: {
                  inputProps: { type: "password" },
                },
              },
            }}
          />
          <Alert>
            <Info className="w-4 h-4" />
            <AlertTitle>
              {t("employees.password_requirements_title")}
            </AlertTitle>
            <AlertDescription>
              <p>
                <i>TODO: these are not final yet!</i>
              </p>
              <p>
                <i>
                  TODO: backend errors should be gracefully handled (show
                  password is too weak / user already exists / etc. to the
                  user)!
                </i>
              </p>
              <p>
                <i>
                  TODO: backend should allow leaving password empty (for
                  unchanged password)!
                </i>
              </p>
              <p>
                {t("employees.password_requirements", {
                  minLength: minPasswordLength,
                })}
              </p>
              <p>{t("employees.edit.keep_password")}</p>
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    </EditItemWrapper>
  );
}
