import { useTranslation } from "react-i18next";
import i18next from "@/lib/i18n";
import TermsEn from "@/pages/terms/TermsEn";
import TermsNl from "@/pages/terms/TermsNl";

export default function Terms() {
  const { t } = useTranslation();
  const lng = i18next.language;

  return (
    <div className="py-6 md:py-12 lg:py-16">
      <div className="container px-4 md:px-6">
        <div className="prose prose-gray max-w-none dark:prose-invert">
          <div className="pb-4 space-y-2 border-b border-gray-200 dark:border-gray-800">
            <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
              {t("terms.title")}
            </h1>
          </div>
          <div className="mx-auto prose max-w-none">
            {lng === "nl" ? <TermsNl /> : <TermsEn />}
          </div>
        </div>
      </div>
    </div>
  );
}
