import type { TFunction } from "i18next";
import {
  capacityType,
  durationType,
  type BookableResponseDTO,
} from "@/client/private";
import type { DataTableFilterField } from "@/types/table.ts";

export function getBookableTableFilters(
  t: TFunction,
): Array<DataTableFilterField<BookableResponseDTO>> {
  return [
    {
      label: t("bookables.properties.name"),
      value: "name",
      placeholder: t("bookables.properties.name_placeholder"),
    },
    {
      label: t("bookables.properties.capacity_type"),
      value: "capacityType",
      options: Object.values(capacityType).map((type) => {
        return {
          label: t(`bookables.capacity_type.${type.toLowerCase()}`),
          value: type,
        };
      }),
    },
    {
      label: t("bookables.properties.duration_type"),
      value: "durationType",
      options: Object.values(durationType).map((type) => {
        return {
          label: t(`bookables.duration_type.${type.toLowerCase()}`),
          value: type,
        };
      }),
    },
  ];
}
