export type ZoomLevel = {
  id: string;
  titleUnits: string;
  titleCount: number;
  startSlot: number;
  slotSizeMinutes: number;
  slotFormats: string[];
};

export const zoomLevels: ZoomLevel[] = [
  {
    id: "0",
    titleUnits: "date_time.hours",
    titleCount: 1,
    startSlot: 6,
    slotSizeMinutes: 60,
    slotFormats: ["HH:mm"],
  },
  {
    id: "1",
    titleUnits: "date_time.hours",
    titleCount: 6,
    startSlot: 1,
    slotSizeMinutes: 6 * 60,
    slotFormats: ["dd MMM", "HH:mm"],
  },
  {
    id: "2",
    titleUnits: "date_time.hours",
    titleCount: 12,
    startSlot: 0,
    slotSizeMinutes: 12 * 60,
    slotFormats: ["dd MMM", "HH:mm"],
  },
  {
    id: "3",
    titleUnits: "date_time.days",
    titleCount: 1,
    startSlot: 0,
    slotSizeMinutes: 24 * 60,
    slotFormats: ["dd MMM"],
  },
];

export function getZoomLevel(id: string) {
  const result = zoomLevels.find((zoomLevel) => zoomLevel.id === id);
  if (!result) {
    throw new Error(`Zoom level with id ${id} not found.`);
  }
  return result;
}
