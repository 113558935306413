import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { RegisterForm } from "@/components/forms/auth/register-form.tsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";

export const Register = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mx-auto my-6 max-w-lg">
        <Card>
          <CardHeader>
            <CardTitle>{t("registration.title")}</CardTitle>
            <CardDescription>{t("registration.description")}</CardDescription>
          </CardHeader>

          <CardContent>
            <RegisterForm />

            <div className="mt-4 text-center text-sm">
              {t("registration.already_have_an_account")}{" "}
              <Link className="underline" to="/login">
                {t("login.login")}
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};
