import {
  useMutation,
  useQueryClient,
  type QueryKey,
} from "@tanstack/react-query";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnFiltersState,
  type PaginationState,
  type SortingState,
  type VisibilityState,
} from "@tanstack/react-table";
import type { Dispatch, SetStateAction } from "react";
import * as React from "react";
import type { ListSettingDTO } from "@/client/private";
import { upsertListSettingMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import {
  toFiltersSetting,
  toFiltersState,
  toPaginationState,
  toSortingSetting,
  toSortingState,
} from "@/components/data-table/data-table-mappings";
import type { DataTableFilterField } from "@/types/table";

export interface RequiredRowProps {
  id: string;
}

interface UseDataTableProps<TData extends RequiredRowProps, TValue> {
  data: Array<TData>;
  columns: Array<ColumnDef<TData, TValue>>;
  totalPages: number;
  pageIndex: number;
  setPageIndex: (index: number) => void;
  listSettings: ListSettingDTO;
  filterFields?: Array<DataTableFilterField<TData>>;
  queryKey: QueryKey;
}

export function useDataTable<TData extends RequiredRowProps, TValue>({
  data,
  columns,
  totalPages,
  listSettings,
  pageIndex,
  setPageIndex,
  filterFields = [],
  queryKey,
}: UseDataTableProps<TData, TValue>) {
  const client = useQueryClient();
  const { mutate, isPending } = useMutation({ ...upsertListSettingMutation() });

  const [rowSelection, setRowSelection] = React.useState({});

  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    toFiltersState(listSettings),
  );

  const searchableColumnIds = React.useMemo(
    () =>
      filterFields
        .filter((field) => !field.options)
        .map((search) => search.value as string),
    [filterFields],
  );

  React.useEffect(() => {
    if (
      JSON.stringify(toFiltersState(listSettings)) !==
      JSON.stringify(columnFilters)
    ) {
      mutate(
        {
          body: {
            ...listSettings,
            filters: [...toFiltersSetting(columnFilters, searchableColumnIds)],
          },
        },
        {
          onSuccess: () => {
            client.invalidateQueries({ queryKey: queryKey });
          },
        },
      );
    }
  }, [
    client,
    columnFilters,
    listSettings,
    mutate,
    queryKey,
    searchableColumnIds,
  ]);

  const getRowId = (originalRow: TData, index: number) => {
    if (originalRow && Object.hasOwn(originalRow, "id")) {
      return originalRow.id;
    }
    return index.toString();
  };

  /* Start setter functions */
  const setSorting: Dispatch<SetStateAction<SortingState>> = (sorting) => {
    if (typeof sorting === "function") {
      const sortingState = sorting([]);
      mutate(
        {
          body: {
            ...listSettings,
            ...toSortingSetting(sortingState),
          },
        },
        {
          onSuccess: () => {
            client.invalidateQueries({ queryKey: queryKey });
          },
        },
      );
    }
  };

  const setPagination: Dispatch<SetStateAction<PaginationState>> = (paging) => {
    if (typeof paging === "function") {
      const pagingState = paging({
        pageIndex: pageIndex,
        pageSize: listSettings.pageSize,
      });
      setPageIndex(pagingState.pageIndex);
      mutate(
        { body: { ...listSettings, pageSize: pagingState.pageSize } },
        {
          onSuccess: () => {
            client.invalidateQueries({ queryKey: queryKey });
          },
        },
      );
    }
  };
  /* End setter functions */

  const table = useReactTable<TData>({
    data,
    columns,
    pageCount: totalPages ?? -1,
    state: {
      columnVisibility,
      rowSelection,
      sorting: toSortingState(listSettings),
      pagination: toPaginationState(listSettings, pageIndex),
      columnFilters: columnFilters,
    },

    getRowId: getRowId,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    enableRowSelection: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
  });

  return { table, isPending };
}
