import { Heading } from "@/components/ui/heading.tsx";

interface HeaderProps {
  title: string;
  description: string;
  totalElements?: number;
}

export const ItemListHeader = ({
  title,
  description,
  totalElements,
}: HeaderProps) => {
  return (
    <div className="flex items-start justify-between">
      <Heading
        title={`${title} (${totalElements === undefined ? "..." : totalElements})`}
        description={description}
      />
    </div>
  );
};
