import type { ColumnDef } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { ResourceResponseDTO } from "@/client/private";
import { DataTableTextCell } from "@/components/data-table/cells/data-table-text-cell.tsx";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header.tsx";
import { ResourceTableActionCell } from "@/components/resources/list/ResourceTableActionCell";

export function getResourceColumns(
  t: TFunction,
  onDataInvalidated: () => void,
): Array<ColumnDef<ResourceResponseDTO>> {
  return [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("resources.properties.name")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<ResourceResponseDTO> row={row} name="name" />
      ),
    },
    {
      accessorKey: "capacity",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("resources.properties.capacity")}
        />
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <ResourceTableActionCell
          row={row}
          onDataInvalidated={onDataInvalidated}
        />
      ),
    },
  ];
}
