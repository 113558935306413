import { useTranslation } from "react-i18next";
import { ProfileSettingsForm } from "@/components/forms/settings/profile-settings-form.tsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";

export const Profile = () => {
  const { t } = useTranslation();

  return (
    <div className="grid gap-6">
      <Card>
        <CardHeader>
          <CardTitle>{t("settings.profile.title")}</CardTitle>
          <CardDescription>{t("settings.profile.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <ProfileSettingsForm />
        </CardContent>
      </Card>
    </div>
  );
};
