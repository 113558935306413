import type { ZoomLevel } from "@/components/planner/zoom-levels";
import { useDateFormat } from "@/hooks/use-date-format";

type HeaderRowProps = {
  timeSlots: Date[];
  zoomLevel: ZoomLevel;
};

export function HeaderRow({ timeSlots, zoomLevel }: HeaderRowProps) {
  const { formatDate } = useDateFormat();

  return (
    <>
      <div className="bg-muted rounded-t-md row-start-1 row-end-1 col-span-full self-stretch"></div>
      {timeSlots.map((slot, index) => (
        <div
          key={slot.toString()}
          style={{ gridColumn: `${index + 1} / span 1` }}
          className="font-semibold p-2 relative w-[200%] row-start-1 row-end-1 flex flex-col items-center"
        >
          {zoomLevel.slotFormats.map((slotFormat) => (
            <div key={slotFormat}>{formatDate(slot, slotFormat)}</div>
          ))}
        </div>
      ))}
    </>
  );
}
