import { addDays, addHours, addMinutes } from "date-fns";
import { v4 as uuid } from "uuid";
import type { BookableResponseDTO } from "@/client/private";

export type PlannerReservation = {
  id: string;
  startAt: Date;
  until: Date;
  title: string;
  capacity: number;
  notes?: string;
};

export type PlannerReservationRequest = PlannerReservation;

export type PlannerResource = {
  id: string;
  name: string;
  reservations: PlannerReservation[];
};

export type PlannerBookable = Pick<
  BookableResponseDTO,
  "id" | "name" | "description"
> & {
  resources: PlannerResource[];
  requests: PlannerReservationRequest[];
};

export type FlatReservation = PlannerReservation & {
  bookableId: string;
  resourceId: string;
  bookableName: string;
  resourceName: string;
};

export function getFlatReservations(
  bookables: PlannerBookable[],
): FlatReservation[] {
  const flatReservations: FlatReservation[] = [];
  for (const bookable of bookables) {
    for (const resource of bookable.resources) {
      for (const reservation of resource.reservations) {
        flatReservations.push({
          ...reservation,
          bookableId: bookable.id,
          resourceId: resource.id,
          bookableName: bookable.name,
          resourceName: resource.name,
        });
      }
    }
  }
  return flatReservations;
}

export function findResource(resourceId: string, bookables: PlannerBookable[]) {
  for (const bookable of bookables) {
    const resource = bookable.resources.find((r) => r.id === resourceId);
    if (resource) {
      return resource;
    }
  }
  return null;
}

export function findRequest(requestId: string, bookables: PlannerBookable[]) {
  for (const bookable of bookables) {
    const request = bookable.requests.find((r) => r.id === requestId);
    if (request) {
      return request;
    }
  }
  return null;
}

export function createMockResources(
  currentDate: Date,
  bookables: PlannerBookable[],
) {
  for (let i = 0; i < 10; i++) {
    const bookable = bookables[Math.floor(Math.random() * bookables.length)]!;
    const resource: PlannerResource = {
      id: `bookable-${bookable.id}-resource-${bookable.resources.length}`,
      name: `Resource ${bookable.resources.length + 1}`,
      reservations: [],
    };
    bookable.resources.push(resource);

    const from = addMinutes(currentDate, Math.floor(Math.random() * 24 * 60));
    const until = addMinutes(from, Math.floor(Math.random() * 480));
    const capacity = Math.floor(Math.random() * 10) + 1;
    const title = `Reservation ${bookable.resources.length + 1}`;
    resource.reservations.push({
      id: `${resource.id}-reservation-${i}`,
      startAt: from,
      until,
      capacity,
      title,
    });
  }

  return bookables;
}

export function getPlannerMockData(today: Date): PlannerBookable[] {
  return [
    {
      id: uuid(),
      name: "Bowling lane",
      description: "Bowling lane for 6 people",
      requests: [
        {
          id: uuid(),
          startAt: addHours(today, 8),
          until: addHours(today, 10),
          title: "Mr Grey",
          capacity: 4,
          notes: "BBQ dinner",
        },
      ],
      resources: [
        {
          id: uuid(),
          name: "Lane 1",
          reservations: [
            {
              id: uuid(),
              startAt: addHours(today, 10.25),
              until: addHours(today, 12.52),
              title: "Mr Smith",
              capacity: 6,
              notes: "Shoes size 42",
            },
            {
              id: uuid(),
              startAt: addHours(today, 13),
              until: addHours(today, 16),
              title: "Mrs Pink",
              capacity: 2,
              notes: "Request for bumpers",
            },
          ],
        },
        {
          id: uuid(),
          name: "Lane 2",
          reservations: [
            {
              id: uuid(),
              startAt: addHours(today, 9),
              until: addHours(today, 11),
              title: "Mr White",
              capacity: 4,
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      name: "Disco golf",
      description: "Disco golf for 4 people",
      requests: [
        {
          id: uuid(),
          startAt: addHours(today, 12),
          until: addHours(today, 15),
          title: "Mr Indigo",
          capacity: 2,
        },
      ],
      resources: [
        {
          id: uuid(),
          name: "Red course",
          reservations: [
            {
              id: uuid(),
              startAt: addHours(today, 14),
              until: addHours(today, 16),
              title: "Mr Brown",
              capacity: 10,
            },
          ],
        },
        {
          id: uuid(),
          name: "Blue course",
          reservations: [],
        },
      ],
    },
    {
      id: uuid(),
      name: "Table tennis",
      description: "Table tennis for 2 people",
      requests: [],
      resources: [
        {
          id: uuid(),
          name: "Green table",
          reservations: [
            {
              id: uuid(),
              startAt: addHours(today, 20),
              until: addHours(today, 22),
              title: "Mr Orange",
              capacity: 2,
            },
            {
              id: uuid(),
              startAt: addDays(addHours(today, 15), 1),
              until: addDays(addHours(today, 18), 1),
              title: "Mr Orange",
              capacity: 2,
            },
          ],
        },
        {
          id: uuid(),
          name: "Blue table",
          reservations: [],
        },
      ],
    },
  ];
}
