import { tz } from "@date-fns/tz";
import { format } from "date-fns";
import { enUS, nl } from "date-fns/locale";
import { useProfile } from "@/hooks/use-profile";

export const useDateFormat = () => {
  const { data } = useProfile();

  const locale = data?.language === "NL" ? nl : enUS;
  const profileZone = tz("Europe/Amsterdam");
  const utcZone = tz("UTC");

  return {
    formatDate: (date: string | number | Date, formatString: string) =>
      format(date, formatString, { locale, in: profileZone }),
    formatDateUtc: (date: string | number | Date, formatString: string) =>
      format(date, formatString, { locale, in: utcZone }),
    profileZone,
    utcZone,
  };
};
