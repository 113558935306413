import type { TFunction } from "i18next";
import { z } from "zod";

export function createResourceSchema(t: TFunction) {
  return z.object({
    name: z
      .string({
        required_error: t("common.field_required", {
          field: t("resources.properties.name"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("resources.properties.name"),
        }),
      })
      .describe(t("resources.properties.name")),
    capacity: z
      .number({
        required_error: t("common.field_required", {
          field: t("resources.properties.capacity"),
        }),
      })
      .int({ message: t("resources.add.capacity_invalid") })
      .min(1, { message: t("resources.add.capacity_min", { min: 1 }) })
      .default(1)
      .describe(t("resources.properties.capacity")),
    shareLimit: z
      .number({
        required_error: t("common.field_required", {
          field: t("resources.properties.share_limit"),
        }),
      })
      .int({ message: t("resources.add.share_limit_invalid") })
      .min(1, { message: t("resources.add.share_limit_min", { min: 1 }) })
      .default(1)
      .describe(t("resources.properties.share_limit")),
    maxCapacitySize: z
      .number({
        required_error: t("common.field_required", {
          field: t("resources.properties.max_capacity"),
        }),
      })
      .int({ message: t("resources.add.max_capacity_invalid") })
      .min(1, { message: t("resources.add.max_capacity_min", { min: 1 }) })
      .default(1)
      .describe(t("resources.properties.max_capacity")),
  });
}

export type ResourceSchemaValues = z.infer<
  ReturnType<typeof createResourceSchema>
>;
