import { createFileRoute } from "@tanstack/react-router";
import { Edit } from "@/pages/unavailability/Edit";

export const Route = createFileRoute("/_auth/unavailability/$id")(
  {
    component: Edit,
    staticData: {
      breadcrumb: "unavailability.edit.title",
    },
  },
);
