import { ChevronDown, ChevronRight } from "lucide-react";
import { ResourceRow } from "@/components/planner/ResourceRow";
import type { PlannerBookable } from "@/components/planner/planner-mock-data";

type BookableRowProps = {
  bookable: PlannerBookable;
  timeSlots: Date[];
  tableRowMapping: Map<string, number>;
  isCollapsed: boolean;
  isLastBookable: boolean;
  handleCollapse: (bookableId: string) => void;
};

export function BookableRow({
  bookable,
  timeSlots,
  tableRowMapping,
  isCollapsed,
  isLastBookable,
  handleCollapse,
}: BookableRowProps) {
  const collapsibleRowStyle =
    isCollapsed && isLastBookable ? "rounded-b-md border-b" : "";

  return (
    <>
      <div
        className={`bg-background p-2 text-sm text-foreground flex items-center justify-center hover:cursor-pointer hover:bg-accent border-x border-muted ${collapsibleRowStyle}`}
        style={{
          gridColumn: `1 / span ${timeSlots.length + 1}`,
          gridRow: `${tableRowMapping.get(bookable.id)} / span 1`,
        }}
        onClick={() => handleCollapse(bookable.id)}
      >
        {isCollapsed ? (
          <ChevronRight className="h-4 w-4 mx-1" />
        ) : (
          <ChevronDown className="h-4 w-4 mx-1" />
        )}
        <span className="w-full text-center">{bookable.name}</span>
      </div>
      {!isCollapsed &&
        bookable.resources.map((resource, index, array) => {
          const isLastResource = isLastBookable && index === array.length - 1;
          return (
            <ResourceRow
              key={resource.id}
              resource={resource}
              timeSlots={timeSlots}
              tableRowMapping={tableRowMapping}
              isLastResource={isLastResource}
            />
          );
        })}
    </>
  );
}
