import type { RefObject } from "react";
import { useEffect, useState } from "react";

export function useElementSize(elementRef: RefObject<HTMLElement | null>) {
  const element = elementRef.current ?? document.documentElement;

  const [elementSize, setElementSize] = useState({
    width: element?.clientWidth,
    height: element?.clientWidth,
  });

  useEffect(() => {
    if (!element) {
      return;
    }

    const handleResize = () => {
      setElementSize({
        width: element.clientWidth,
        height: element.clientHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [element]);

  return { elementSize };
}
