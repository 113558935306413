import type { ColumnDef } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { EmployeeResponseDTO } from "@/client/private";
import { DataTableTextCell } from "@/components/data-table/cells/data-table-text-cell.tsx";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header.tsx";
import { EmployeeTableActionCell } from "@/components/employees/list/EmployeeTableActionCell";
import { toUiLanguage } from "@/lib/language";

export function getEmployeeTableColumns(
  t: TFunction,
  onDataInvalidated: () => void,
): Array<ColumnDef<EmployeeResponseDTO>> {
  return [
    {
      accessorKey: "firstName",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("employees.properties.first_name")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<EmployeeResponseDTO> row={row} name="firstName" />
      ),
    },
    {
      accessorKey: "lastName",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("employees.properties.last_name")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<EmployeeResponseDTO> row={row} name="lastName" />
      ),
      filterFn: (row, id, value) =>
        Array.isArray(value) && value.includes(row.getValue(id)),
    },
    {
      accessorKey: "language",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("employees.properties.language")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<EmployeeResponseDTO> row={row} name="language" />
      ),
      accessorFn: (row) => toUiLanguage(row.language),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <EmployeeTableActionCell
          row={row}
          onDataInvalidated={onDataInvalidated}
        />
      ),
    },
  ];
}
