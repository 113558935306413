import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Info } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { createEmployeeMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import AutoForm from "@/components/auto-form";
import { EditItemWrapper } from "@/components/common/EditItemWrapper";
import type { AddEmployeeSchemaValues } from "@/components/employees/employee-schema";
import {
  createAddEmployeeSchema,
  minPasswordLength,
} from "@/components/employees/employee-schema";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useProfile } from "@/hooks/use-profile";
import { toBackendLanguage } from "@/lib/language";

export function AddEmployeeForm() {
  const { t } = useTranslation();
  const profile = useProfile();
  const navigate = useNavigate();
  const create = useMutation({
    ...createEmployeeMutation(),
  });

  const formSchema = createAddEmployeeSchema(t, profile.data?.language);
  const [formValues, setFormValues] = useState<AddEmployeeSchemaValues | null>(
    null,
  );

  const handleSubmit = (values: AddEmployeeSchemaValues) => {
    create.mutate(
      {
        body: {
          ...values,
          password: values.passwords.password,
          language: toBackendLanguage(values.language),
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.create_success", { item: t("employees.employee") }),
          );
          navigate({ to: "/employees" });
        },
        onError: () =>
          toast.error(
            t("common.create_error", { item: t("employees.employee") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      disabled={create.isPending || !formValues}
      onClick={() => formValues && handleSubmit(formValues)}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemWrapper title={t("employees.add.title")} buttons={buttons}>
      <Card>
        <CardHeader>
          <CardTitle>{t("employees.employee")}</CardTitle>
          <CardDescription>{t("employees.add.description")}</CardDescription>
        </CardHeader>
        <CardContent className="space-y-5">
          <AutoForm
            formSchema={formSchema}
            onValidityChange={setFormValues}
            fieldConfig={{
              passwords: {
                showLabel: false,
                password: {
                  inputProps: { type: "password" },
                },
                confirmation: {
                  inputProps: { type: "password" },
                },
              },
            }}
          />
          <Alert>
            <Info className="w-4 h-4" />
            <AlertTitle>
              {t("employees.password_requirements_title")}
            </AlertTitle>
            <AlertDescription>
              <p>
                <i>TODO: these are not matched to the backend yet!</i>
              </p>
              <p>
                <i>
                  TODO: backend errors should be gracefully handled (show
                  password is too weak / user already exists / etc. to the
                  user)!
                </i>
              </p>
              <p>
                {t("employees.password_requirements", {
                  minLength: minPasswordLength,
                })}
              </p>
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    </EditItemWrapper>
  );
}
