import { NIL } from "uuid";
import { client } from "@/client/public";

export const setPublicClient = () => {
  client.setConfig({
    responseType: "json", // needed for hey-client response transformers to work
    timeout: 300_000,
    baseURL: "https://api.cbrm.cloud/api",
    headers: {
      "X-company": NIL,
    },
  });
};
