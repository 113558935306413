import { useTranslation } from "react-i18next";

export default function TermsNl() {
  const { t } = useTranslation();

  return (
    <>
      <p className="py-2 text-gray-500 dark:text-gray-400">
        {t("terms.last_updated", { date: "February 29, 2024" })}
      </p>
      <p>TODO: Dutch terms and conditions text.</p>
    </>
  );
}
