import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { EmployeeResponseDTO } from "@/client/private";
import { listEmployee, listView, type PageEmployeeDTO } from "@/client/private";
import { listEmployeeQueryKey } from "@/client/private/@tanstack/react-query.gen";
import { AddItemButton } from "@/components/common/AddItemButton";
import { ItemListHeader } from "@/components/common/ItemListHeader";
import { DataTableLayoutWrapper } from "@/components/data-table/data-table-layout.tsx";
import { getEmployeeTableColumns } from "@/components/employees/list/employee-table-columns";
import { getEmployeeTableFilters } from "@/components/employees/list/employee-table-filters";
import { useDataTableSettings } from "@/hooks/use-data-table-settings.ts";

export const EmployeeTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    content,
    totalPages,
    pageIndex,
    setPageIndex,
    queryKey,
    listSettings,
    totalElements,
    isFetched,
    refetch,
  } = useDataTableSettings<PageEmployeeDTO>(
    listEmployee,
    listEmployeeQueryKey,
    listView.EMPLOYEE,
  );

  const filterFields = useMemo(() => getEmployeeTableFilters(t), [t]);
  const columns = useMemo(
    () => getEmployeeTableColumns(t, refetch),
    [t, refetch],
  );

  const handleRowClick = (row: Row<EmployeeResponseDTO>) => {
    navigate({ to: `/employees/${row.id}` });
  };

  return (
    <DataTableLayoutWrapper
      above={
        <ItemListHeader
          title={t("employees.title")}
          description={t("employees.description")}
          totalElements={totalElements}
        />
      }
      toolbar={
        <AddItemButton
          onClick={() => navigate({ to: "/employees/add" })}
          item={t("employees.employee")}
        />
      }
      columns={columns}
      content={content}
      totalPages={totalPages}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      onRowClick={handleRowClick}
      filterFields={filterFields}
      queryKey={queryKey}
      listSettings={listSettings}
      isFetched={isFetched}
    />
  );
};
