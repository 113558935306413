import { useQuery } from "@tanstack/react-query";
import { addWeeks, startOfWeek } from "date-fns";
import {
  getAllOpeningHoursOptions,
  getOpeningPeriodOptions,
} from "@/client/private/@tanstack/react-query.gen";
import { OpeningHourTable } from "@/components/opening-hours/list/OpeningHourTable";
import { UpcomingOpeningHours } from "@/components/opening-hours/list/UpcomingOpeningHours";
import { useDateFormat } from "@/hooks/use-date-format";

export const List = () => {
  const openingHourQuery = useQuery({
    ...getAllOpeningHoursOptions(),
  });

  const { utcZone } = useDateFormat();
  const start = startOfWeek(new Date(), { weekStartsOn: 1, in: utcZone });
  const end = addWeeks(start, 2);

  const openingPeriodQuery = useQuery({
    ...getOpeningPeriodOptions({
      path: {
        // TODO: this cast is needed because hey-api/axios doesn't serialize the dates correctly
        start: start.toISOString() as unknown as Date,
        end: end.toISOString() as unknown as Date,
      },
    }),
  });

  const handleDataInvalidated = () => {
    openingHourQuery.refetch();
    openingPeriodQuery.refetch();
  };

  return (
    <main className="space-y-5">
      <OpeningHourTable
        {...openingHourQuery}
        onDataInvalidated={handleDataInvalidated}
      />
      <UpcomingOpeningHours {...openingPeriodQuery} start={start} end={end} />
    </main>
  );
};
