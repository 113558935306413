import type { Row } from "@tanstack/table-core";
import type { Duration } from "dayjs/plugin/duration";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useDayjs } from "@/hooks/use-dayjs";

interface DataTableDurationCell<T> {
  row: Row<T>;
  name: Extract<keyof T, string>;
}

export const DataTableDurationCell = <T,>({
  row,
  name,
}: DataTableDurationCell<T>) => {
  const dayjs = useDayjs();
  const { t } = useTranslation();

  const text = row.getValue<string>(name);
  const duration = prettyPrintDuration(dayjs.duration(text), t);

  return <span>{duration}</span>;
};

function prettyPrintDuration(duration: Duration, t: TFunction) {
  if (duration.asSeconds() === 0) {
    return t("date_time.zero");
  }

  const days = Math.floor(duration.asDays());

  const remainder = duration.subtract({ days });
  const hours = remainder.hours();
  const minutes = remainder.minutes();
  const seconds = remainder.seconds();

  const parts: string[] = [];
  if (days > 0) {
    parts.push(t("date_time.days", { count: days }));
  }
  if (hours > 0) {
    parts.push(t("date_time.hours", { count: hours }));
  }
  if (minutes > 0) {
    parts.push(t("date_time.minutes", { count: minutes }));
  }
  if (seconds > 0) {
    parts.push(t("date_time.seconds", { count: seconds }));
  }

  return parts.join(", ");
}
