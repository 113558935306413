import type { Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

interface DataTableBooleanCell<T> {
  row: Row<T>;
  name: Extract<keyof T, string>;
}

export const DataTableBooleanCell = <T,>({
  row,
  name,
}: DataTableBooleanCell<T>) => {
  const { t } = useTranslation();
  const text = row.getValue<boolean>(name) ? t("common.yes") : t("common.no");
  return <span className="capitalize">{text}</span>;
};
