import type { TFunction } from "i18next";
import type { ReservationResponseDTO } from "@/client/private";
import type { DataTableFilterField } from "@/types/table.ts";

export function getReservationTableFilters(
  t: TFunction,
): Array<DataTableFilterField<ReservationResponseDTO>> {
  return [
    {
      label: t("reservations.properties.start_at"),
      value: "startAt",
      placeholder: t("reservations.properties.start_at_placeholder"),
    },
  ];
}
