import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getAllBookablesOptions,
  getAllResourcesOptions,
} from "@/client/private/@tanstack/react-query.gen";
import { SearchInput } from "@/components/common/SearchInput";
import { SkeletonCard } from "@/components/ui/SkeletonCard";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";

type ResourceSelectionProps = {
  value: string[];
  onChange: (value: string[]) => void;
};

export function ResourceSelection({ value, onChange }: ResourceSelectionProps) {
  const { t } = useTranslation();
  const { data: resources } = useQuery({
    ...getAllResourcesOptions(),
  });
  const { data: bookables } = useQuery({
    ...getAllBookablesOptions(),
  });

  const [expandedBookables, setExpandedBookables] = useState<
    Map<string, boolean>
  >(new Map());
  const [nameFilter, setNameFilter] = useState("");

  if (resources == null || bookables == null) {
    return SkeletonCard();
  }

  function handleCheckChange(resourceId: string) {
    const newSelection = value.includes(resourceId)
      ? value.filter((id) => id !== resourceId)
      : [...value, resourceId];
    onChange(newSelection);
  }

  function getResourcesByBookableId(bookableId: string) {
    return (
      resources?.content.filter(
        (resource) =>
          resource.bookableId === bookableId &&
          (!nameFilter ||
            resource.name
              .toLocaleLowerCase()
              .includes(nameFilter.toLocaleLowerCase())),
      ) ?? []
    );
  }

  function expandBookables(ids: string[]) {
    const newExpandedBookables = new Map<string, boolean>();
    bookables?.content.forEach(({ id }) =>
      newExpandedBookables.set(id, ids.includes(id)),
    );
    setExpandedBookables(newExpandedBookables);
  }

  // Initially expand bookables that have selected resources
  const bookablesWithSelectedResources = resources.content
    .filter((resource) => value.some((id) => id === resource.id))
    .map((resource) => resource.bookableId);

  const expandedBookablesIncludingInitialSelection = bookables.content
    .map((bookable) => bookable.id)
    .filter(
      (bookableId) =>
        expandedBookables.get(bookableId) ??
        bookablesWithSelectedResources.includes(bookableId),
    );

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("resources.title")}</CardTitle>
        <CardDescription>
          {t("unavailability.resources_description")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <SearchInput
          placeholder={t("unavailability.filter_resources_placeholder")}
          value={nameFilter}
          onChange={setNameFilter}
        />
        <Accordion
          type="multiple"
          className="w-full"
          onValueChange={expandBookables}
          value={expandedBookablesIncludingInitialSelection}
        >
          {bookables.content.map((bookable) => {
            const bookableResources = getResourcesByBookableId(bookable.id);
            return (
              <AccordionItem key={bookable.id} value={bookable.id}>
                <AccordionTrigger className="flex justify-between">
                  {bookable.name} ({bookableResources.length})
                </AccordionTrigger>
                <AccordionContent className="flex flex-col gap-3 justify-start ps-5">
                  {bookableResources.length === 0 && (
                    <p className="text-sm text-gray-500">
                      {t("unavailability.bookable_no_resources")}
                    </p>
                  )}
                  {bookableResources.map((resource) => (
                    <div key={resource.id} className="flex gap-2">
                      <Checkbox
                        id={`resource-${resource.id}`}
                        onCheckedChange={() => handleCheckChange(resource.id)}
                        checked={value.includes(resource.id)}
                      />
                      <label
                        htmlFor={`resource-${resource.id}`}
                        className="text-sm font-medium leading-none"
                      >
                        {resource.name}
                      </label>
                    </div>
                  ))}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </CardContent>
    </Card>
  );
}
