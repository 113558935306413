import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";

export default function NotFound() {
  const { t } = useTranslation();

  function goBack() {
    window.history.back();
  }

  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mb-16 items-center justify-center text-center">
      <span className="text-[10rem] font-extrabold leading-none text-white">
        404
      </span>
      <h2 className="my-2 font-heading text-2xl font-bold">{t("404.title")}</h2>
      <p>{t("404.description")}</p>
      <div className="mt-8 flex justify-center gap-2">
        <Button variant="default" size="lg" onClick={goBack}>
          {t("404.go_back")}
        </Button>
        <Link to="/">
          <Button variant="ghost" size="lg">
            {t("404.go_home")}
          </Button>
        </Link>
      </div>
    </div>
  );
}
