import { language } from "@/client/public";

export const Languages = {
  NL: "Nederlands",
  EN: "English",
};

export const toUiLanguage = (data?: keyof typeof language) =>
  Languages[data || language.EN];

export const toBackendLanguage = (lang: string) => {
  if (lang === Languages.NL) {
    return language.NL;
  }

  return language.EN;
};
