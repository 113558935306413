import { useTranslation } from "react-i18next";
import { FormControl } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type FrequencySelectProps = {
  value: string;
  onChange: (value: string) => void;
};

export function FrequencySelect({ value, onChange }: FrequencySelectProps) {
  const { t } = useTranslation();

  return (
    <Select onValueChange={onChange} value={value}>
      <FormControl>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <SelectItem value="DAILY">{t("recurrence.daily")}</SelectItem>
        <SelectItem value="WEEKLY">{t("recurrence.weekly")}</SelectItem>
        <SelectItem value="MONTHLY">{t("recurrence.monthly")}</SelectItem>
        <SelectItem value="YEARLY">{t("recurrence.yearly")}</SelectItem>
      </SelectContent>
    </Select>
  );
}
