import { addMinutes, differenceInMinutes, format } from "date-fns";
import { ReservationDetails } from "@/components/planner/ReservationDetails";
import type { PlannerReservation } from "@/components/planner/planner-mock-data";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

type ReservationProps = {
  reservation: PlannerReservation;
  timeSlots: Date[];
  slotSizeMinutes: number;
  gridRow: number;
  isRequest: boolean;
};

export default function ReservationCell({
  reservation,
  timeSlots,
  slotSizeMinutes,
  gridRow,
  isRequest,
}: ReservationProps) {
  const getReservationStyle = (reservation: PlannerReservation) => {
    let startSlotIndex = timeSlots.findIndex(
      (slot) =>
        slot <= reservation.startAt &&
        addMinutes(slot, slotSizeMinutes) > reservation.startAt,
    );
    startSlotIndex = startSlotIndex === -1 ? 0 : startSlotIndex;
    const startSlot = timeSlots[startSlotIndex];

    let endSlotIndex = timeSlots.findIndex(
      (slot) =>
        slot < reservation.until &&
        addMinutes(slot, slotSizeMinutes) >= reservation.until,
    );
    endSlotIndex = endSlotIndex === -1 ? timeSlots.length - 1 : endSlotIndex;
    const endSlot = timeSlots[endSlotIndex];

    if (!startSlot || !endSlot) {
      return {};
    }

    const columnStart = startSlotIndex + 2;
    const columnEnd = endSlotIndex + 3;
    const gridSizeInMinutes = (columnEnd - columnStart) * slotSizeMinutes;

    const startOffset =
      startSlot >= reservation.startAt
        ? 0
        : differenceInMinutes(reservation.startAt, startSlot) /
          gridSizeInMinutes;

    const endSlotEnd = addMinutes(endSlot, slotSizeMinutes);
    const endOffset =
      endSlotEnd <= reservation.until
        ? 0
        : differenceInMinutes(endSlotEnd, reservation.until) /
          gridSizeInMinutes;

    return {
      gridColumn: `${columnStart} / ${columnEnd}`,
      gridRow: `${gridRow} / span 1`,
      marginLeft: `${startOffset * 100}%`,
      marginRight: `${endOffset * 100}%`,
    };
  };

  const backgroundStyle = isRequest ? "bg-sky-600/75" : "bg-sky-900";

  return (
    <Popover>
      <PopoverTrigger asChild className="cursor-pointer">
        <div
          key={reservation.id}
          style={getReservationStyle(reservation)}
          className="p-1 min-w-0 min-h-0"
        >
          <div
            className={`rounded-md p-2 h-full text-foreground ${backgroundStyle}`}
          >
            <div className="text-sm text-ellipsis overflow-hidden whitespace-nowrap">
              {reservation.title}
            </div>
            <div className="text-xs text-ellipsis overflow-hidden whitespace-nowrap">
              {format(reservation.startAt, "HH:mm")}-
              {format(reservation.until, "HH:mm")} {reservation.capacity}{" "}
              persons
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <ReservationDetails reservation={reservation} />
      </PopoverContent>
    </Popover>
  );
}
